import DiscoTableSortDropdown, {
  DiscoTableSortDirection,
  DiscoTableSortOption,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import { TestIDProps } from "@utils/typeUtils"

export interface GuestsSortDropdownProps extends TestIDProps {
  sortState: DiscoTableToolbarStateWithSort
  setSortState: (state: DiscoTableToolbarStateWithSort) => void
}

function GuestsSortDropdown({ sortState, setSortState }: GuestsSortDropdownProps) {
  const SORT_OPTIONS: DiscoTableSortOption[] = [
    {
      value: "member name",
      title: "Name",
      context: {
        order: { field: "member_name" },
      },
    },
    {
      value: "join date",
      title: "Join Date",
      context: {
        order: { field: "joined_on" },
      },
    },
  ]

  return (
    <DiscoTableSortDropdown
      testid={"GuestsSortDropdown"}
      options={SORT_OPTIONS}
      onOptionSelect={handleSortSelect}
      toolbarState={sortState}
    />
  )

  function handleSortSelect(
    sortOption: DiscoTableSortOption,
    direction: DiscoTableSortDirection
  ) {
    setSortState({
      search: sortState.search,
      sort: {
        id: sortOption.value,
        order: sortOption.context!.order,
        title: sortOption.title,
      },
      direction,
    })
  }
}

export default GuestsSortDropdown
