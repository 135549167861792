import ContentFormAttachmentsSection from "@/content/form/sections/ContentFormAttachmentsSection"
import ContentFormDescriptionSection from "@/content/form/sections/ContentFormDescriptionSection"
import ContentFormScheduleReleaseSection from "@/content/form/sections/ContentFormScheduleReleaseSection"
import ContentFormTitleSection from "@/content/form/sections/ContentFormTitleSection"
import { PostFormState } from "@/content/form/util/contentFormUtil"
import InlineContentHeroAndProperties from "@/content/inline/InlineContentHeroAndProperties"
import FormStore from "@/core/form/store/FormStore"
import { CreatePostInput } from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { EditPostInput } from "@/post/edit/__generated__/EditPostModalMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { LexicalChildProps } from "@components/editor/LexicalEditor"
import { DiscoEditorMentionsFragment$key } from "@components/editor/__generated__/DiscoEditorMentionsFragment.graphql"
import { observer } from "mobx-react-lite"
import { MutableRefObject } from "react"

interface Props {
  form: FormStore<(CreatePostInput | EditPostInput) & PostFormState>
  contentKey?: DiscoEditorMentionsFragment$key
  editorButtonsRef?: MutableRefObject<HTMLDivElement | null>
  setEditorRef?: LexicalChildProps["onMount"]
}

function PostFormFields({ form, contentKey, editorButtonsRef, setEditorRef }: Props) {
  const classes = useStyles()

  return (
    <>
      {form.state.step === "default" ? (
        <>
          <div className={classes.fieldWrapper}>
            <InlineContentHeroAndProperties
              testid={"PostFormFields"}
              mode={"edit"}
              contentForm={form}
              alwaysAllowAttachmentUpload
              showAttachmentsList={false}
            />
            <ContentFormTitleSection
              form={form}
              classes={{ input: classes.titleInput, inputRoot: classes.titleInputRoot }}
              placeholder={"Enter a title (required)"}
            />
          </div>
          <ContentFormDescriptionSection
            form={form}
            showOutline={false}
            placeholder={"Share something great..."}
            contentKey={contentKey}
            config={"post"}
            editorButtonsRef={editorButtonsRef}
            setEditorRef={setEditorRef}
          />
          <ContentFormAttachmentsSection form={form} hideCreateButton hideDropzone />
        </>
      ) : (
        <ContentFormScheduleReleaseSection form={form} />
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  titleInputRoot: {
    backgroundColor: "transparent",
    padding: theme.spacing(1, 1.5, 1, 2),
  },
  titleInput: {
    ...theme.typography["body-lg"],
    ...theme.typography.modifiers.fontWeight[600],
  },
  fieldWrapper: {
    display: "grid",
    gap: theme.spacing(2.5),
  },
}))

export default observer(PostFormFields)
