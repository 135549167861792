import CommunityBadge from "@/community/CommunityBadge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { MessageCommunityGuestsFormStore } from "@/organization/people/actions/message/MessageCommunityGuestsDrawer"
import { MessageCommunityMembersFormStore } from "@/organization/people/actions/message/MessageCommunityMembersDrawerContent"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: MessageCommunityMembersFormStore | MessageCommunityGuestsFormStore
}

function MessageCommunityMembersEmailFormFields({ form }: Props) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  return (
    <>
      <div>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"Send From"}
        </DiscoText>
        <div className={classes.organizationName}>
          <CommunityBadge size={24} organizationKey={activeOrganization} />
          <DiscoText variant={"body-sm"}>{activeOrganization.name}</DiscoText>
        </div>
      </div>
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Subject"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.subject)}
        errorMessages={form.errorsByField.subject}
        marginBottom={0}
      >
        <DiscoInput
          fullWidth
          data-testid={"MessageCommunityMembersForm.subject"}
          placeholder={"Type your email subject here"}
          value={form.state.emailMessageInput?.subject || ""}
          onChange={(e) =>
            (form.state.emailMessageInput = {
              subject: e.target.value,
              richEditorBody: form.state.emailMessageInput?.richEditorBody || "",
            })
          }
        />
      </DiscoFormControl>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  organizationName: {
    height: "40px",
    paddingInline: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default observer(MessageCommunityMembersEmailFormFields)
