import React from "react"
import { Helmet } from "react-helmet-async"

type GoogleAnalyticsConfig = {
  googleAnalyticsId: string
}

const GoogleAnalyticsScript: React.FC<GoogleAnalyticsConfig> = ({
  googleAnalyticsId,
}) => {
  // Note: The page view tracking is removed from the snippet
  // Manually track page view using usePageLoad instead

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          // Google Consent Mode
          gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'wait_for_update': 500});
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}', {
            send_page_view: false
          });
        `}
      </script>
    </Helmet>
  )
}

export default GoogleAnalyticsScript
