import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import CommunityGuestCheckListTitle, {
  GuestCheckListFormStore,
} from "@/organization/people/member-checklist/CommunityGuestCheckListTitle"
import { CommunityGuestCheckListPaginationQuery } from "@/organization/people/member-checklist/__generated__/CommunityGuestCheckListPaginationQuery.graphql"
import { CommunityGuestCheckListQuery } from "@/organization/people/member-checklist/__generated__/CommunityGuestCheckListQuery.graphql"
import { CommunityGuestCheckList_PaginationFragment$key } from "@/organization/people/member-checklist/__generated__/CommunityGuestCheckList_PaginationFragment.graphql"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoCheckbox, DiscoCheckboxSkeleton, DiscoSpinner } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Collapse } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const MEMBERS_PER_PAGE = 10

interface CommunityGuestCheckListProps extends TestIDProps {
  form: GuestCheckListFormStore
  memberships: GuestCheckList[]
  isCollapsible?: boolean
}

function CommunityGuestCheckList({
  testid = "CommunityGuestCheckList",
  form,
  isCollapsible,
  memberships,
}: CommunityGuestCheckListProps) {
  const activeOrganization = useActiveOrganization()!

  const { isOpen, onToggle } = useDisclosure()

  const classes = useStyles({
    isCollapsible,
    isExpanded: isOpen,
  })

  const { organization } = useLazyLoadQuery<CommunityGuestCheckListQuery>(
    graphql`
      query CommunityGuestCheckListQuery(
        $id: ID!
        $guestMembershipIds: [ID!]
        $orderBy: GuestMembershipsOrderBy
        $direction: OrderDirection
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...CommunityGuestCheckList_PaginationFragment
              @arguments(
                guestMembershipIds: $guestMembershipIds
                orderBy: $orderBy
                direction: $direction
                first: $first
                after: $after
                last: $last
                before: $before
              )
          }
        }
      }
    `,
    {
      first: MEMBERS_PER_PAGE,
      id: activeOrganization.id,
      orderBy: "member_name",
      direction: "ASC",
      guestMembershipIds: memberships.map((m) => m.guestMembershipId),
    }
  )

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    CommunityGuestCheckListPaginationQuery,
    CommunityGuestCheckList_PaginationFragment$key
  >(
    graphql`
      fragment CommunityGuestCheckList_PaginationFragment on Organization
      @refetchable(queryName: "CommunityGuestCheckListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        guestMembershipIds: { type: "[ID!]" }
        orderBy: { type: "GuestMembershipsOrderBy" }
        direction: { type: "OrderDirection" }
      ) {
        guestMemberships(
          guestMembershipIds: $guestMembershipIds
          orderBy: $orderBy
          direction: $direction
          first: $first
          after: $after
          last: $last
          before: $before
        ) @connection(key: "CommunityGuestCheckList__guestMemberships") {
          edges {
            node {
              id
              email
              user {
                id
                ...ProfileAvatarWithDetailsFragment
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const guestMemberships = Relay.connectionToArray(data?.guestMemberships)

  return (
    <div className={classes.container} data-testid={testid}>
      <CommunityGuestCheckListTitle
        testid={`${testid}.title`}
        form={form}
        isCollapsible={Boolean(isCollapsible)}
        isExpanded={isOpen}
        onToggle={onToggle}
      />
      <Collapse in={isCollapsible ? isOpen : true} className={classes.collapseArea}>
        <div className={classes.listContainer}>
          {guestMemberships.map((gm) => {
            const isChecked = form.state.memberships.some(
              (m) => m.guestMembershipId === gm.id
            )
            const omTestId = `${testid}.member.${gm.email}`

            return (
              <div key={gm.id} className={classes.memberItem}>
                <DiscoCheckbox
                  label={undefined}
                  onChange={() =>
                    toggleSelected({
                      guestMembershipId: gm.id,
                      userId: gm.user.id,
                      email: gm.email || "",
                    })
                  }
                  checked={isChecked}
                  testid={`${omTestId}.checkbox`}
                />
                <ProfileAvatarWithDetails
                  userKey={gm.user}
                  details={gm.email}
                  classes={{ details: classes.memberDetails }}
                  testid={omTestId}
                  linkToProfile={false}
                />
              </div>
            )
          })}
          {hasNext && (
            <DiscoScrolledIntoView
              isLoading={isLoadingNext}
              onScrolledIntoView={() => loadNext(MEMBERS_PER_PAGE)}
              skeleton={
                <div className={classes.spinnerContainer}>
                  <DiscoSpinner size={"sm"} />
                </div>
              }
            />
          )}
        </div>
      </Collapse>
    </div>
  )

  function toggleSelected(membership?: GuestCheckList) {
    if (!membership) return
    const isChecked = form.state.memberships.some(
      (m) => m.guestMembershipId === membership.guestMembershipId
    )
    if (isChecked) {
      form.state.memberships.replace(
        form.state.memberships.filter(
          (m) => m.guestMembershipId !== membership.guestMembershipId
        )
      )
    } else form.state.memberships.push(membership)
  }
}

type StyleProps = {
  isCollapsible?: boolean
  isExpanded: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: (props: StyleProps) => ({
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.big,
    ...styleIf(!props.isCollapsible, {
      display: "grid",
      justifyItems: "start",
    }),
  }),
  listContainer: {
    width: "100%",
    display: "grid",
    padding: theme.spacing(2),
    paddingTop: 0,
    gap: theme.spacing(1.5),
    maxHeight: "300px",
    overflowY: "auto",
  },
  memberItem: {
    display: "flex",
  },
  memberDetails: {
    textAlign: "start",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  collapseArea: {
    width: "100%",
  },
}))

export function CommunityGuestCheckListSkeleton({
  isCollapsible,
  form,
}: CommunityGuestCheckListProps) {
  const { isOpen, onToggle } = useDisclosure()

  const classes = useStyles({
    isCollapsible,
    isExpanded: isOpen,
  })

  return (
    <div className={classes.container}>
      <CommunityGuestCheckListTitle
        form={form}
        isCollapsible={Boolean(isCollapsible)}
        isExpanded={isOpen}
        onToggle={onToggle}
      />
      <Collapse in={isCollapsible ? isOpen : true}>
        <div className={classes.listContainer}>
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
        </div>
      </Collapse>
    </div>
  )
}

export default Relay.withSkeleton<CommunityGuestCheckListProps>({
  skeleton: CommunityGuestCheckListSkeleton,
  component: observer(CommunityGuestCheckList),
})
