import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { DiscoSwitch, DiscoText } from "@disco-ui"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"

type Props = {
  form: ContentFormStore
}

function PostCommentsToggle(props: Props) {
  const { form } = props
  const permissions = useActiveProductOrOrganizationPermissions()

  if (!permissions.has("comments.disable")) return null

  return (
    <DiscoSwitch
      data-testid={"PostFormFields.Checkbox.show-comments"}
      testid={"PostFormFields.Checkbox.show-comments"}
      name={"toggle-show-comments"}
      labelPlacement={"end"}
      checked={form.state.content.showComments!}
      onChange={() =>
        (form.state.content = {
          ...form.state.content,
          showComments: !form.state.content?.showComments,
        })
      }
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"} display={"inline"}>
          {"Enable comments"}
        </DiscoText>
      }
    />
  )
}

export default PostCommentsToggle
