import { DiscoIcon, SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { TestIDProps } from "@utils/typeUtils"

type InvitedOption = SelectOption<boolean | null>

export type CommunityGuestReportTableInviteFilterState = {
  hasBeenInvited: boolean | null
}

interface CommunityGuestReportTableInviteFilterProps extends TestIDProps {
  filterState: CommunityGuestReportTableInviteFilterState
  setFilterState: (state: CommunityGuestReportTableInviteFilterState) => void
}

function CommunityGuestReportTableInviteFilter({
  filterState,
  setFilterState,
}: CommunityGuestReportTableInviteFilterProps) {
  const options: InvitedOption[] = [
    { value: null, title: "-" },
    { value: true, title: "Yes" },
    { value: false, title: "No" },
  ]

  return (
    <DiscoTableFilterDropdown
      testid={"CommunityGuestReportTable.invite-select"}
      isNested
      options={options}
      onOptionSelect={(option: InvitedOption | null) =>
        setFilterState({ hasBeenInvited: option ? option.value : null })
      }
      selectedOption={options.find(
        (option) => option.value === filterState.hasBeenInvited
      )}
      dropdown={{
        icon: <DiscoIcon icon={"user-add"} />,
        label: "Disco Invite Sent",
      }}
    />
  )
}

export default CommunityGuestReportTableInviteFilter
