/**
 * @generated SignedSource<<9e3892db05f0d6da1c9ae2776e5b12c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharePostButtonFragment$data = {
  readonly id: string;
  readonly releasedAt: string | null;
  readonly productId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "SharePostButtonFragment";
};
export type SharePostButtonFragment$key = {
  readonly " $data"?: SharePostButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharePostButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharePostButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "fb9bbafea9c9611b5d7315975093a3cb";

export default node;
