import { ContentType } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import ContentFormPreviewEmailButton from "@/content/form/buttons/ContentFormPreviewEmailButton"
import ContentFormNotificationEmailCtaField from "@/content/form/sections/ContentFormNotificationEmailCtaField"
import ContentFormUtil, { PostFormState } from "@/content/form/util/contentFormUtil"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import EditPostForm from "@/post/edit/EditPostForm"
import {
  EditPostInput,
  EditPostModalMutation,
} from "@/post/edit/__generated__/EditPostModalMutation.graphql"
import { EditPostModalQuery } from "@/post/edit/__generated__/EditPostModalQuery.graphql"
import { FeedSelection } from "@/post/FeedSelectorDropdown"
import PostCommentsToggle from "@/post/PostCommentsToggle"
import { PostUtil } from "@/post/util/PostUtil"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoChip,
  DiscoIcon,
  DiscoModal,
  DiscoModalProps,
  DiscoText,
} from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

type Props = Pick<DiscoModalProps, "onClose"> & {
  id: GlobalID
  onEdit?: VoidFunction
}

function EditPostModal(props: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const isWebView = useIsWebView()
  const unsavedChangesModal = useInitUnsavedChangesModalContext()
  const editorButtonsRef = useRef<HTMLDivElement>(null)

  const { post } = useLazyLoadQuery<EditPostModalQuery>(
    graphql`
      query EditPostModalQuery($id: ID!) {
        post: node(id: $id) {
          ... on Post {
            id
            product {
              id
              name
              slug
              status
              startDate
              waitingRoomEndsAt
            }
            content {
              name
              showComments
              richEditorDescriptionContent
              notificationEmailCtaButton
              attachments {
                edges {
                  node {
                    id
                    name
                    mediaUrl
                  }
                }
              }
              coverPhoto
              coverVideo
              coverVideoAsset {
                id
              }
              coverVideoDurationSeconds
              sendNotificationEmail
              notificationEmailSubject
              richEditorNotificationEmailBodyContent
              releaseDatetime
              createdBy {
                id
              }
              ...DiscoEditorMentionsFragment
            }
            feed {
              id
              name
              ...usePermissionsFragment
            }
            ...usePermissionsFragment
          }
        }
      }
    `,
    { id: props.id }
  )

  const postPermissions = usePermissions(post)
  const feedPermissions = usePermissions(post?.feed)

  const contentType: ContentType = "post"
  const postIsReleased = post?.content?.releaseDatetime
    ? Boolean(new Date(post.content.releaseDatetime) <= new Date())
    : true

  const form = useFormStore<EditPostModalMutation, EditPostInput & PostFormState>(
    graphql`
      mutation EditPostModalMutation($input: EditPostInput!) {
        response: editPost(input: $input) {
          node {
            feedId
            content {
              releaseDatetime
              sendNotificationEmail
              notificationEmailSubject
              richEditorNotificationEmailBodyContent
            }
            ...PostCardFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      postId: props.id,
      content: {
        name: post?.content?.name,
        richEditorDescriptionContent: post?.content?.richEditorDescriptionContent,
        attachments: Relay.connectionToArray(post?.content?.attachments),
        coverPhoto: post?.content?.coverPhoto,
        coverVideo: post?.content?.coverVideo,
        coverVideoAssetId: post?.content?.coverVideoAsset?.id,
        coverVideoDurationSeconds: post?.content?.coverVideoDurationSeconds,
        notificationEmailSubject: post?.content?.notificationEmailSubject,
        richEditorNotificationEmailBodyContent:
          post?.content?.richEditorNotificationEmailBodyContent,
        sendNotificationEmail: post?.content?.sendNotificationEmail,
        releaseDatetime: post?.content?.releaseDatetime,
        releaseNow: false,
        showComments: post?.content?.showComments,
        notificationEmailCtaButton: post?.content?.notificationEmailCtaButton,
        createdById: post?.content?.createdBy?.id,
      },
      isScheduled:
        Boolean(post?.content?.releaseDatetime) &&
        Boolean(new Date(post!.content!.releaseDatetime!) > new Date()),
      step: "default",
      selectedFeed: { ...post?.feed, product: { ...post?.product } } as FeedSelection,
    }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged])

  const modalTitle = PostUtil.getPostFormModalTitle("edit", form.state.step)

  if (!postPermissions.has("post.edit")) {
    return (
      <DiscoWarningModal
        isOpen={true}
        onClose={props.onClose}
        modalContentLabel={"Cannot Edit Modal"}
        testid={"CannotEditPostModal"}
        title={"Cannot Edit Post"}
        description={"You can only edit your own posts."}
        cancelButtonText={"Close"}
      />
    )
  }

  return (
    <UnsavedChangesModalProvider {...unsavedChangesModal}>
      <DiscoModal
        {...props}
        shouldCloseOnOverlayClick={false}
        isOpen
        onClose={() => handleClose(false)}
        title={
          <div className={classes.modalTitleContainer}>
            <DiscoText variant={"body-lg-600"}>{modalTitle}</DiscoText>
            {post?.feed?.name && (
              <>
                <DiscoText variant={"body-xs-500"}>{"in"}</DiscoText>
                <DiscoChip
                  label={renderFeedName()}
                  borderRadius={"medium"}
                  style={{ alignSelf: "center" }}
                />
              </>
            )}
          </div>
        }
        modalContentLabel={modalTitle}
        width={"720px"}
        maxWidth={"90vw"}
        height={isWebView ? "100%" : undefined}
        maxHeight={isWebView ? "95vh" : undefined}
        testid={"EditPostModal"}
        shouldCloseOnEsc={false}
        isFullScreenInWebView
        body={
          <EditPostForm
            contentKey={post?.content}
            form={form}
            onEdit={props.onEdit}
            onClose={handleClose}
            editorButtonsRef={editorButtonsRef}
          />
        }
        classes={{ buttons: classes.buttonsOverride, body: classes.body }}
        buttons={
          <Grid container direction={"column"}>
            {form.state.step === "schedule" && form.state.selectedFeed.product?.id && (
              <Grid item style={{ marginBottom: theme.spacing(2.5) }}>
                <ContentFormNotificationEmailCtaField form={form} contentType={"post"} />
              </Grid>
            )}
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={
                form.state.step === "default" ? "space-between" : "flex-end"
              }
            >
              {form.state.step === "default" ? (
                <div className={classes.buttonsContainer}>
                  <PostCommentsToggle form={form} />
                  <div ref={editorButtonsRef} />
                </div>
              ) : form.state.step === "schedule" ? (
                <Grid item className={classes.buttonsContainer}>
                  <ContentFormPreviewEmailButton
                    key={"preview-email"}
                    form={form}
                    contentType={contentType}
                    disabled={
                      !form.state.content.sendNotificationEmail ||
                      ContentFormUtil.areNotificationFieldsEmpty(form)
                    }
                    productId={form.state.selectedFeed.product?.id}
                    ctaUrl={
                      post?.id && post.feed?.id && post.product?.slug
                        ? `${location.origin}${generatePath(
                            ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST,
                            {
                              feedId: post.feed.id,
                              productSlug: post.product.slug,
                            }
                          )}?postId=${post.id}`
                        : undefined
                    }
                  />
                </Grid>
              ) : null}
              <Grid item className={classes.buttonsContainer}>
                {renderButtons()}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </UnsavedChangesModalProvider>
  )

  function handleClose(ignoreUnsavedChanges: boolean) {
    if (!props.onClose) return
    if (ignoreUnsavedChanges) {
      props.onClose()
      return
    }
    unsavedChangesModal.handleLeave({
      onLeave: props.onClose,
    })
  }

  function renderFeedName() {
    if (!post?.feed?.name) return ""
    return `${post?.product?.name ? `${post?.product?.name} / ` : ""}${post.feed.name}`
  }

  function renderButtons() {
    switch (form.state.step) {
      case "default":
        return renderDefaultStepButtons()
      case "schedule":
        return renderScheduleStepButtons()
      default:
        return []
    }
  }

  function renderDefaultStepButtons() {
    const isEditorEmpty = EditorUtils.isEmpty(
      form.state.content.richEditorDescriptionContent
    )
    const isIncompletePost = isEditorEmpty || !form.state.content.name
    const isProductPost = Boolean(post?.product)

    if (isProductPost) {
      // Released Product Post
      if (postIsReleased)
        return (
          <Form.SubmitButton
            testid={"EditPostModal.button.post"}
            id={"EditPostForm"}
            form={form}
            disabled={form.isSubmitting || isIncompletePost}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        )

      // Scheduled Product Post
      return [
        ...ArrayUtils.spreadIf(
          <DiscoButton
            key={"Schedule"}
            testid={"EditPostModal.button.schedule"}
            leftIcon={<DiscoIcon icon={"calendar"} />}
            color={"grey"}
            variant={"outlined"}
            onClick={() =>
              PostUtil.moveToScheduleStep(form, {
                formValidator: isDefaultFormValid,
              })
            }
            disabled={isIncompletePost}
          >
            {"Schedule"}
          </DiscoButton>,
          feedPermissions.has("posts.schedule")
        ),
        // don't use Form.SubmitButton because we DO want the form to prevent submit if no changes
        // but we want the user to be able to post immediately without any changes
        <DiscoButton
          key={"PostNow"}
          testid={"EditPostModal.button.post"}
          form={"EditPostForm"}
          type={"submit"}
          disabled={form.isSubmitting || isIncompletePost}
          shouldDisplaySpinner={form.isSubmitting}
          onClickCapture={() => {
            // before submitting the form, set the correct values to release a post immediately
            form.state.isScheduled = false
            form.state.content.sendNotificationEmail = false
            form.state.content.releaseDatetime = null
            form.state.content.releaseNow = true
          }}
        >
          {"Post Now"}
        </DiscoButton>,
      ]
    }

    // Released Community Post
    if (postIsReleased)
      return (
        <Form.SubmitButton
          testid={"EditPostModal.button.post"}
          id={"EditPostForm"}
          form={form}
          disabled={form.isSubmitting || isIncompletePost}
        >
          {"Save Changes"}
        </Form.SubmitButton>
      )

    // Scheduled Community Post
    return (
      <DiscoButton
        testid={"EditPostModal.button.post"}
        onClick={() =>
          ContentFormUtil.moveToScheduleStep(form, {
            formValidator: isDefaultFormValid,
          })
        }
        disabled={isIncompletePost}
      >
        {"Next Step"}
      </DiscoButton>
    )
  }

  function renderScheduleStepButtons() {
    return [
      <DiscoButton
        key={"previous"}
        testid={"EditPostModal.button.previous"}
        color={"grey"}
        variant={"outlined"}
        onClick={() =>
          post?.product
            ? PostUtil.moveToDefaultStep(form)
            : ContentFormUtil.moveToDefaultStep(form)
        }
      >
        {"Previous"}
      </DiscoButton>,
      <Form.SubmitButton
        key={"post"}
        id={"EditPostForm"}
        testid={"EditPostModal.button.post"}
        form={form}
        disabled={form.isSubmitting}
      >
        {"Save Changes"}
      </Form.SubmitButton>,
    ]
  }

  function isDefaultFormValid() {
    if (EditorUtils.isEmpty(form.state.content.richEditorDescriptionContent)) {
      displayErrorToast("Must include a description")
      return false
    }
    return true
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalTitleContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "baseline",
  },
  buttonsOverride: {
    marginLeft: "unset",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  body: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5, 1, 1),
    },
  },
}))

export default Relay.withSkeleton<Props>({
  component: observer(EditPostModal),
  skeleton: () => <Skeleton />,
})
