import React from "react"
import { Helmet } from "react-helmet-async"

type GoogleTagManagerConfig = {
  googleTagManagerid: string
  functionName?: string
  dataLayerName?: string
}

const GoogleTagManagerScript: React.FC<GoogleTagManagerConfig> = ({
  googleTagManagerid,
  functionName = "gtag",
  dataLayerName = "gtmDataLayer",
}) => {
  return (
    <Helmet>
      <script>
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','${dataLayerName}','${googleTagManagerid}');
        `}
      </script>

      <script>
        {`
          window.${dataLayerName} = window.${dataLayerName} || [];
          function ${functionName}() {
            window.${dataLayerName}.push(arguments);
          }
          // Google Consent Mode
          ${functionName}('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'wait_for_update': 500});
        `}
      </script>
    </Helmet>
  )
}

export default GoogleTagManagerScript
