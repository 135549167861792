import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import useInitSentry from "@/core/hook/useInitSentry"
import {
  trackPageView,
  useInitCookieHub,
} from "@/organization/tracking/cookie-consent/utils/trackingUtils"
import FacebookPixelScript from "@/organization/tracking/scripts/FacebookPixelScript"
import GoogleAnalyticsScript from "@/organization/tracking/scripts/GoogleAnalyticsScript"
import GoogleTagManagerScript from "@/organization/tracking/scripts/GoogleTagManagerScript"
import usePageLoad from "@utils/hook/usePageLoad"
import { Location } from "history"
import React, { useContext } from "react"

const CookieConsentContext = React.createContext({ hasConsented: false })

export function useCookieConsent() {
  return useContext(CookieConsentContext)
}

const CookieConsentProvider: React.FC = ({ children }) => {
  const activeOrganization = useActiveOrganization()
  usePageLoad((pageLocation: Location) => {
    trackPageView(pageLocation)
  })
  const hasConsented = useInitCookieHub({ cookieHubId: COOKIE_HUB_ID })

  // Inititalize Sentry as soon as possible when the user grants consent
  useInitSentry(hasConsented)

  return (
    <CookieConsentContext.Provider value={{ hasConsented }}>
      {/** Load the Google Analytics */}
      {activeOrganization?.googleAnalyticsId && (
        <GoogleAnalyticsScript googleAnalyticsId={activeOrganization.googleAnalyticsId} />
      )}
      {/** Load the Facebook Pixel */}
      {activeOrganization?.facebookPixelId && (
        <FacebookPixelScript facebookPixelId={activeOrganization.facebookPixelId} />
      )}
      {/** Load the Google Tag Manager */}

      {INTERNAL_GTM_TAG_ID && (
        <GoogleTagManagerScript
          googleTagManagerid={INTERNAL_GTM_TAG_ID}
          dataLayerName={"discoInternalLayer"}
          functionName={"gtmInternalGtag"}
        />
      )}

      {activeOrganization?.googleTagManagerId && (
        <GoogleTagManagerScript
          googleTagManagerid={activeOrganization.googleTagManagerId}
        />
      )}

      {children}
    </CookieConsentContext.Provider>
  )
}

export default CookieConsentProvider
