import CreatePostModal from "@/post/add/CreatePostModal"
import { CreatePostWithAIButtonProps } from "@/post/list/empty-state/CreatePostWithAIButton"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { TestIDProps } from "@utils/typeUtils"
import { useCallback } from "react"

export type CreatePostParams = {
  createPost?: "true"
}

export interface CreatePostButtonProps extends TestIDProps {
  /** The feed we want to post into */
  feedId?: GlobalID
  refetch?: VoidFunction
  className?: string
  children?: OverridableDiscoButtonChildren
  /** Whether we open the modal by default if the createPost param is present */
  checkQueryParamForModalOpen?: boolean
  aiTemplate?: CreatePostWithAIButtonProps["aiTemplate"]
}

function CreatePostButton({
  testid = "CreatePostButton",
  feedId,
  refetch,
  className,
  children,
  checkQueryParamForModalOpen,
  aiTemplate,
}: CreatePostButtonProps) {
  const [{ createPost }, setParams] = useQueryParamState<CreatePostParams>()

  return (
    <OverridableDiscoButton
      leftIcon={"add"}
      className={className}
      testid={testid}
      // prevent ai create post button triggering CreatePostInput container button modal open
      stopPropagation={!!aiTemplate}
      modalOpenByDefault={checkQueryParamForModalOpen && createPost === "true"}
      modal={useCallback(
        (props) => {
          // Reset form state on each open
          if (!props.isOpen) return null
          return (
            <CreatePostModal
              isOpen={
                // allow modal to be opened by query param, or overridable button modal state
                (checkQueryParamForModalOpen && createPost === "true") || props.isOpen
              }
              onClose={() => {
                props.onClose()
                setParams({ createPost: undefined })
              }}
              feedId={feedId}
              refetch={refetch}
              aiTemplate={aiTemplate}
            />
          )
        },
        // no 'refetch' dependency to avoid resetting on re-renders due to unstable refetch props
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [feedId]
      )}
    >
      {children || "Add Post"}
    </OverridableDiscoButton>
  )
}

export default CreatePostButton
