/**
 * @generated SignedSource<<463bc5cc5c6dafe575133f1a533025fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PostPreviewEmailFormFieldsQuery$variables = {
  richEditorBody: string;
  params?: string | null;
  entityId: string;
};
export type PostPreviewEmailFormFieldsQuery$data = {
  readonly preview: {
    readonly data: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  } | null;
};
export type PostPreviewEmailFormFieldsQuery = {
  variables: PostPreviewEmailFormFieldsQuery$variables;
  response: PostPreviewEmailFormFieldsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "params"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "richEditorBody"
},
v3 = [
  {
    "alias": "preview",
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "emailTemplate",
            "value": "NewAnnouncementNotification"
          },
          {
            "kind": "Variable",
            "name": "entityId",
            "variableName": "entityId"
          },
          {
            "kind": "Variable",
            "name": "params",
            "variableName": "params"
          },
          {
            "kind": "Variable",
            "name": "richEditorBody",
            "variableName": "richEditorBody"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "PreviewEmailTemplateResponse",
    "kind": "LinkedField",
    "name": "previewEmailTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PostPreviewEmailFormFieldsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PostPreviewEmailFormFieldsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "cfb1d34a28de5bea728feebe0c0dd26c",
    "id": null,
    "metadata": {},
    "name": "PostPreviewEmailFormFieldsQuery",
    "operationKind": "query",
    "text": "query PostPreviewEmailFormFieldsQuery(\n  $richEditorBody: JSON!\n  $params: JSON\n  $entityId: ID!\n) {\n  preview: previewEmailTemplate(input: {entityId: $entityId, emailTemplate: NewAnnouncementNotification, richEditorBody: $richEditorBody, params: $params}) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "acfd43aa563b39fe84f636e7ba1ded9d";

export default node;
