import { ContentType } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import ContentFormPreviewEmailButton from "@/content/form/buttons/ContentFormPreviewEmailButton"
import ContentFormNotificationEmailCtaField from "@/content/form/sections/ContentFormNotificationEmailCtaField"
import ContentFormUtil, { PostFormState } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabels } from "@/core/context/LabelsContext"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { CreatePostParams } from "@/post/add/CreatePostButton"
import CreatePostForm from "@/post/add/CreatePostForm"
import {
  CreatePostInput,
  CreatePostModalMutation,
} from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { CreatePostModalQuery } from "@/post/add/__generated__/CreatePostModalQuery.graphql"
import FeedSelectorDropdown, { FeedSelection } from "@/post/FeedSelectorDropdown"
import { CreatePostWithAIButtonProps } from "@/post/list/empty-state/CreatePostWithAIButton"
import PostCommentsToggle from "@/post/PostCommentsToggle"
import { PostUtil } from "@/post/util/PostUtil"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorUtils from "@components/editor/EditorUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoAlert,
  DiscoButton,
  DiscoChip,
  DiscoIcon,
  DiscoLink,
  DiscoModal,
  DiscoModalProps,
  DiscoText,
} from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useQueryParams } from "@utils/url/urlUtils"
import { add, set } from "date-fns"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type Props = Pick<DiscoModalProps, "onClose"> & {
  /**
   * pass a feedId to create a post within that feed
   * prevents render of FeedSelectorDropdown
   */
  feedId?: GlobalID
  initialTitle?: string
  initialDescription?: string
  refetch?: VoidFunction
  // Is the post made from a bot suggestion
  botResponseId?: GlobalID
  // if AI template is passed, we stream a post into the modal/form
  aiTemplate?: CreatePostWithAIButtonProps["aiTemplate"]
  isOpen?: boolean
}

function CreatePostModal(props: Props) {
  const { feedId, initialTitle, initialDescription, refetch, botResponseId } = props
  const { globalAdd } = useFeatureFlags()
  const activeOrganization = useActiveOrganization()!
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  // CreatePostModal/Button can be used outside product context ex: Community Home,
  // so don't access activeProduct data except to select the initial feed displayed
  const activeProduct = useActiveProduct()
  const activeProductId = activeProduct?.id
  const { authUser } = useAuthUser()
  const classes = useStyles()
  const theme = useTheme()
  const isWebView = useIsWebView()
  const { createPost } = useQueryParams<CreatePostParams>()
  const isOpen = props.isOpen ?? createPost === "true"
  const isDark = theme.palette.type === "dark"
  const labels = useLabels()
  const isAdmin =
    activeOrganization?.viewerIsOwnerOrAdmin || activeProduct?.viewerIsManagerOrInstructor
  const editorButtonsRef = useRef<HTMLDivElement>(null)

  const { organization, product, feedNode, user } =
    useLazyLoadQuery<CreatePostModalQuery>(
      graphql`
        query CreatePostModalQuery(
          $organizationId: ID!
          $productId: ID!
          $feedId: ID!
          $userId: ID!
        ) {
          organization: node(id: $organizationId) {
            ... on Organization {
              feeds(first: 1, canPost: true) {
                edges {
                  node {
                    id
                    name
                    viewerPermissions
                  }
                }
              }
              products(type: "course") {
                edges {
                  node {
                    id
                    name
                    status
                    slug
                    startDate
                    waitingRoomEndsAt
                    feeds(first: 1, canPost: true) {
                      edges {
                        node {
                          id
                          name
                          viewerPermissions
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          product: node(id: $productId) {
            __typename
            ... on Product {
              id
              name
              slug
              status
              startDate
              waitingRoomEndsAt
              feeds {
                edges {
                  node {
                    id
                    name
                    viewerPermissions
                  }
                }
              }
            }
          }
          feedNode: node(id: $feedId) {
            __typename
            ... on Feed {
              id
              name
              viewerPermissions
              product {
                id
                name
                slug
                status
                startDate
                waitingRoomEndsAt
              }
            }
          }
          user: node(id: $userId) {
            ... on User {
              ...ProfileAvatarWithDetailsFragment
            }
          }
        }
      `,
      {
        organizationId: activeOrganization.id,
        feedId: feedId || "",
        productId: activeProductId || "",
        userId: authUser?.id ? authUser.id : "",
      },
      { fetchPolicy: "network-only" }
    )

  const feed = Relay.narrowNodeType(feedNode, "Feed")
  const initialFeed = getInitialFeed()

  const form = useFormStore<CreatePostModalMutation, CreatePostInput & PostFormState>(
    graphql`
      mutation CreatePostModalMutation($input: CreatePostInput!, $connections: [ID!]!) {
        response: createPost(input: $input) {
          node @prependNode(connections: $connections, edgeTypeName: "PostNodeEdge") {
            id
            feedId
            viewerPermissions
            ...PostCardFragment
            feed {
              isInactive
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      feedId: initialFeed?.id ?? "",
      content: {
        name: initialTitle || "",
        description: "",
        richEditorDescriptionContent: initialDescription || "",
        attachments: observable.array(),
        coverPhoto: null,
        coverVideo: null,
        coverVideoDurationSeconds: null,
        notificationEmailSubject: "",
        richEditorNotificationEmailBodyContent: null,
        notificationEmailCtaButton: "enabled",
        sendNotificationEmail: false,
        showComments: true,
        releaseDatetime: set(
          add(new Date(), {
            days: 1,
          }),
          { hours: 13, minutes: 0 }
        ).toString(),
      },
      isScheduled: false,
      step: "default",
      selectedFeed: initialFeed ?? ({} as FeedSelection),
      botResponseId,
      aiTemplate: props.aiTemplate,
    }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged])

  const contentType: ContentType = "post"
  const modalTitle = PostUtil.getPostFormModalTitle("add", form.state.step)

  const missingProductFeed =
    activeProductId &&
    (!Relay.isNodeType(product, "Product") ||
      !Relay.connectionToArray(product.feeds).length)

  const hasPostPermission =
    form.state.selectedFeed.viewerPermissions?.includes("posts.create")
  const hasSchedulePostPermission =
    form.state.selectedFeed.viewerPermissions?.includes("posts.schedule")

  return (
    <UnsavedChangesModalProvider {...unsavedChangesModal}>
      <DiscoModal
        {...props}
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => handleClose(false)}
        classes={{ body: classes.body }}
        title={
          form.state.step === "default"
            ? user && (
                <ProfileAvatarWithDetails
                  userKey={user}
                  linkToProfile={false}
                  classes={{ details: classes.details }}
                  details={
                    <>
                      <DiscoText
                        variant={"body-xs-500-uppercase"}
                        truncateText={1}
                        color={isDark ? "groovy.onDark.200" : "groovy.grey.400"}
                        display={"inline"}
                        className={classes.detailsText}
                        marginRight={0.5}
                      >
                        {"Posting in"}
                      </DiscoText>
                      {feedId && !globalAdd ? (
                        <DiscoChip
                          data-testid={`CreatePostModal.feedName`}
                          label={renderSelectedFeedName()}
                          color={"blue"}
                          borderRadius={"medium"}
                        />
                      ) : (
                        <FeedSelectorDropdown
                          setFeedSelection={setFeedSelection}
                          selectedFeed={form.state.selectedFeed}
                        >
                          {(buttonProps) => (
                            <div {...buttonProps}>
                              <DiscoChip
                                data-testid={`CreatePostModal.feedName`}
                                label={renderSelectedFeedName()}
                                rightIcon={
                                  <DiscoIcon
                                    icon={"chevron"}
                                    className={classes.chevron}
                                    width={16}
                                    height={16}
                                  />
                                }
                                color={getFeedSelectColor()}
                                borderRadius={"medium"}
                              />
                            </div>
                          )}
                        </FeedSelectorDropdown>
                      )}
                    </>
                  }
                />
              )
            : form.state.step === "schedule"
            ? "Schedule Post"
            : "Share Post"
        }
        modalContentLabel={modalTitle}
        width={"720px"}
        height={isWebView ? "100%" : undefined}
        maxHeight={isWebView ? "95vh" : undefined}
        shouldCloseOnEsc={false}
        testid={"CreatePostModal"}
        body={
          <div
            onDragStart={(event) => {
              // prevent propagation to parent dashboard block to allow dragging editor blocks
              event.stopPropagation()
            }}
          >
            {form.state.selectedFeed.name ? (
              hasPostPermission ? null : (
                <DiscoAlert
                  data-testid={"CreatePostModal.feed-permission-alert"}
                  severity={"warning"}
                  marginBottom={1.5}
                  message={"You do not have permission to post in this Feed."}
                />
              )
            ) : isAdmin ? (
              missingProductFeed ? (
                <DiscoAlert
                  severity={"warning"}
                  marginBottom={1.5}
                  message={
                    <>
                      {`In order to add a post in this ${
                        labels.admin_experience.singular
                      }, you must first install a Feed app to ${
                        activeProductId ? "this" : "an"
                      } Experience. `}
                      <DiscoLink
                        to={
                          "https://support.disco.co/hc/en-us/articles/10830820006804#feedapp-add-feed"
                        }
                        target={"_blank"}
                      >
                        {"Learn how to add an App."}
                      </DiscoLink>
                    </>
                  }
                />
              ) : (
                !initialFeed && (
                  <DiscoAlert
                    severity={"warning"}
                    marginBottom={1.5}
                    message={
                      <>
                        {`In order to add a post in this community, you must first install a Feed app. `}
                        <DiscoLink
                          to={
                            "https://support.disco.co/hc/en-us/articles/10830820006804#feedapp-add-feed"
                          }
                          target={"_blank"}
                        >
                          {"Learn how to add an App."}
                        </DiscoLink>
                      </>
                    }
                  />
                )
              )
            ) : (
              <DiscoAlert
                data-testid={"CreatePostModal.experience-permission-alert"}
                severity={"warning"}
                marginBottom={1.5}
                message={`You do not have permission to post in this ${
                  activeProductId ? labels.experience.singular : "community"
                } `}
              />
            )}

            <CreatePostForm
              form={form}
              onClose={handleClose}
              refetch={refetch}
              feedId={feedId}
              editorButtonsRef={editorButtonsRef}
            />
          </div>
        }
        fullWidthButtons
        isFullScreenInWebView
        buttons={
          <Grid container direction={"column"}>
            {form.state.step === "schedule" && form.state.selectedFeed.product && (
              <Grid item style={{ marginBottom: theme.spacing(2.5) }}>
                <ContentFormNotificationEmailCtaField form={form} contentType={"post"} />
              </Grid>
            )}
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={
                form.state.step === "default" ? "space-between" : "flex-end"
              }
            >
              {form.state.step === "default" ? (
                <div className={classes.buttonsContainer}>
                  <PostCommentsToggle form={form} />
                  <div ref={editorButtonsRef} />
                </div>
              ) : form.state.step === "schedule" ? (
                <Grid item className={classes.buttonsContainer}>
                  <ContentFormPreviewEmailButton
                    key={"preview-email"}
                    form={form}
                    contentType={contentType}
                    productId={form.state.selectedFeed.product?.id}
                    disabled={
                      !form.state.content.sendNotificationEmail ||
                      ContentFormUtil.areNotificationFieldsEmpty(form)
                    }
                  />
                </Grid>
              ) : null}
              <Grid item className={classes.buttonsContainer}>
                {getButtons()}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </UnsavedChangesModalProvider>
  )

  function handleClose(ignoreUnsavedChanges: boolean) {
    if (!props.onClose) return
    if (ignoreUnsavedChanges) {
      props.onClose()
      return
    }
    unsavedChangesModal.handleLeave({
      onLeave: props.onClose,
    })
  }

  function getInitialFeed(): FeedSelection | null | undefined {
    // Posting in specific feed
    if (feedId) {
      if (!Relay.isNodeType(feed, "Feed")) return null
      return feed as unknown as FeedSelection
    }

    // Posting within a product
    if (activeProductId) {
      if (!Relay.isNodeType(product, "Product")) return null
      // Return first feed where user has permission to post, if non found leave empty
      const activeProductFeeds = Relay.connectionToArray(product.feeds)
      const postFeed = activeProductFeeds.find((productFeed) =>
        productFeed.viewerPermissions?.includes("posts.create")
      )
      return postFeed ? { ...postFeed, product } : null
    }

    // Posting from community level. Default first to community feeds,
    // then try to find any feed within a product
    const organizationFeeds = Relay.connectionToArray(organization?.feeds)
    if (organizationFeeds[0]) return organizationFeeds[0]
    const products = Relay.connectionToArray(organization?.products)
    for (const p of products) {
      const feeds = Relay.connectionToArray(p.feeds)
      if (feeds[0]) return { ...feeds[0], product: p }
    }
    return null
  }

  function renderSelectedFeedName() {
    const name = form.state.selectedFeed.product?.name
    if (!form.state.selectedFeed.name) return `No Feed Selected`
    return `${name ? `${name} / ` : ""}${form.state.selectedFeed.name}`
  }

  function getFeedSelectColor() {
    if (!form.state.selectedFeed.name) return "yellow"
    if (!hasPostPermission) return "grey"
    return "blue"
  }

  function getButtons() {
    if (form.state.step === "default") {
      return [
        ...ArrayUtils.spreadIf(
          <DiscoButton
            key={"schedule"}
            testid={"CreatePostModal.button.step-schedule"}
            leftIcon={"calendar"}
            color={"grey"}
            variant={"outlined"}
            onClick={() =>
              PostUtil.moveToScheduleStep(form, {
                formValidator: isDefaultFormValid,
              })
            }
            disabled={shouldDisableSubmission()}
          >
            {"Schedule"}
          </DiscoButton>,
          hasSchedulePostPermission
        ),
        // Post immediately
        <DiscoButton
          key={"confirm"}
          testid={"CreatePostModal.button.submit-now"}
          type={"submit"}
          form={"CreatePostForm"}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={form.isSubmitting || shouldDisableSubmission()}
        >
          {"Post Now"}
        </DiscoButton>,
      ]
    } else if (form.state.step === "schedule") {
      // scheduling is only ever for product-level feeds
      return [
        <DiscoButton
          key={"previous"}
          color={"grey"}
          variant={"outlined"}
          testid={"CreatePostModal.button.previous"}
          onClick={() => PostUtil.moveToDefaultStep(form)}
        >
          {"Previous"}
        </DiscoButton>,
        <DiscoButton
          key={"confirm"}
          testid={"CreatePostModal.button.submit-schedule"}
          type={"submit"}
          form={"CreatePostForm"}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={
            form.isSubmitting ||
            EditorUtils.isEmpty(form.state.content.richEditorDescriptionContent)
          }
        >
          {"Schedule Post"}
        </DiscoButton>,
      ]
    }
    return []
  }

  function setFeedSelection(feedSelection: FeedSelection) {
    const observableFeedSelection = observable({
      id: feedSelection.id,
      name: feedSelection.name,
      viewerPermissions: observable.array(
        feedSelection.viewerPermissions ? [...feedSelection.viewerPermissions] : []
      ),
      product: feedSelection.product ? observable(feedSelection.product) : null,
    })
    form.state.selectedFeed = observableFeedSelection
    form.state.feedId = feedSelection.id
  }

  function isDefaultFormValid() {
    if (EditorUtils.isEmpty(form.state.content.richEditorDescriptionContent)) {
      displayErrorToast("Must include a description")
      return false
    }
    return true
  }

  function shouldDisableSubmission() {
    return (
      !hasPostPermission ||
      !form.state.feedId ||
      !form.state.content.name ||
      EditorUtils.isEmpty(form.state.content.richEditorDescriptionContent)
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  details: {
    height: "unset",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.25),
  },
  detailsText: {
    textTransform: "none",
  },
  chevron: {
    display: "flex",
    transform: "rotate(180deg)",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  body: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5, 1, 1),
    },
  },
}))

export default Relay.withSkeleton<Props>({
  component: observer(CreatePostModal),
  skeleton: () => null,
})
