interface GoogleTagManagerPurchaseProps {
  value: number
  currency: string
  orderId: string
  discountCode?: string
}

class GoogleTagManager {
  static pageView(pathname: string) {
    this._callWith("event", "page_view", {
      pagePath: pathname,
    })
  }

  static grantConsent() {
    this._callWith("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    })
  }

  static revokeConsent() {
    this._callWith("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    })
  }

  static trackPurchase(props: GoogleTagManagerPurchaseProps) {
    const { value, currency, orderId, discountCode } = props
    this._callWith("event", "purchase", {
      checkout: {
        value,
        currency,
        orderId,
        discountCode,
      },
    })
  }

  private static _callWith = (...args: any[]) => {
    if ("gtmInternalGtag" in window)
      window.gtmInternalGtag(...(args as Parameters<Gtag.Gtag>))
    // if the user added their own tag, this will run
    if ("gtmGtag" in window) window.gtmGtag(...(args as Parameters<Gtag.Gtag>))
  }
}

export default GoogleTagManager
