import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import OrganizationGuestListActionDropdown from "@/organization/people/list/item/OrganizationGuestListActionDropdown"
import { OrganizationGuestListRow_Member$key } from "@/organization/people/list/item/__generated__/OrganizationGuestListRow_Member.graphql"
import { generateDateText } from "@/organization/people/list/util/organizationPeopleListUtils"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import useUserTimezone from "@/user/util/useUserTimezone"
import { DiscoCheckbox, DiscoChip, DiscoTable, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { TableCell } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  guestMembershipKey: OrganizationGuestListRow_Member$key
  isSelected: boolean
  toggleSelected: () => void
}

function OrganizationGuestListRow({
  guestMembershipKey,
  isSelected,
  toggleSelected,
  testid,
}: Props) {
  const timeZone = useUserTimezone()
  const classes = useStyles()

  const guestMembership = useFragment<OrganizationGuestListRow_Member$key>(
    graphql`
      fragment OrganizationGuestListRow_Member on GuestMembership {
        id
        email
        createdAt
        hasBeenInvited
        ...OrganizationGuestListActionDropdownFragment
        userMemberships {
          totalCount
          edges {
            node {
              id
              entity
              occurrence {
                name
              }
              user {
                fullName
              }
            }
          }
        }
        user {
          id
          bio
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
      }
    `,
    guestMembershipKey
  )

  if (!guestMembership.userMemberships.edges) return null
  const userMemberships = Relay.connectionToArray(guestMembership.userMemberships)
  const userMembership = userMemberships[0]
  const inviteSent = guestMembership.hasBeenInvited
  if (!userMembership) return null

  return (
    <DiscoTable.Row testid={testid} className={classes.row}>
      {/* Checkbox & User */}
      <TableCell>
        <div className={classes.userCell}>
          {/* Need this so that the select button doesn't click to the profile page */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div onClick={(event) => event.stopPropagation()}>
            <DiscoCheckbox
              className={classes.checkbox}
              label={undefined}
              onChange={toggleSelected}
              checked={isSelected}
              testid={`${testid}.checkbox`}
            />
          </div>
          <ProfileAvatarWithDetails
            userKey={guestMembership.user}
            className={classes.userContainer}
            details={guestMembership.email}
            testid={"ProfileAvatarWithDetails"}
            linkToProfile={false}
          />
        </div>
      </TableCell>

      {/* Has Access to*/}
      <TableCell align={"center"}>
        {guestMembership.userMemberships.totalCount > 1 ? (
          <DiscoTooltip
            content={userMemberships.map((um) => um.occurrence?.name || "").join(", ")}
            placement={"top"}
          >
            <DiscoChip
              testid={`${testid}.accessCount`}
              padding={1.5}
              label={guestMembership.userMemberships.totalCount}
            />
          </DiscoTooltip>
        ) : (
          userMembership?.occurrence && (
            <DiscoText data-testid={`${testid}.occurrenceName`} variant={"body-sm"}>
              {userMembership?.occurrence?.name}
            </DiscoText>
          )
        )}
      </TableCell>

      {/* Date Joined */}
      <TableCell>
        <DiscoText variant={"body-sm"} color={"text.secondary"} noWrap>
          {generateDateText(timeZone, guestMembership.createdAt)}
        </DiscoText>
      </TableCell>
      {/* Disco Invite Sent */}
      <TableCell align={"center"}>
        <DiscoTag testid={`${testid}.inviteStatus`} name={inviteSent ? "Sent" : "No"} />
      </TableCell>

      {/* Action */}
      <TableCell align={"center"}>
        <OrganizationGuestListActionDropdown
          email={guestMembership.email || ""}
          guestMembershipKey={guestMembership}
        />
      </TableCell>
    </DiscoTable.Row>
  )
}

const useStyles = makeUseStyles((theme) => ({
  row: {
    verticalAlign: "top",
  },
  checkbox: {
    zIndex: theme.zIndex.raise1,
    margin: 0,
  },
  userCell: {
    display: "flex",
    alignItems: "center",
  },
  userContainer: {
    minWidth: "200px",
    gap: theme.spacing(1),
  },
}))

export const OrganizationPeopleListSkeletonRow: React.FC = () => (
  <DiscoTable.Row>
    <TableCell>
      <Skeleton width={150} />
    </TableCell>
    <TableCell>
      <Skeleton width={150} />
    </TableCell>
    <TableCell>
      <Skeleton variant={"circle"} width={32} height={32} />
    </TableCell>
  </DiscoTable.Row>
)

export default observer(OrganizationGuestListRow)
