import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import MessageCommunityGuestsButton from "@/organization/people/actions/message/MessageCommunityGuestsButton"
import RemoveCommunityGuestButton from "@/organization/people/actions/remove/RemoveCommunityGuestButton"
import { OrganizationGuestListActionDropdownFragment$key } from "@/organization/people/list/item/__generated__/OrganizationGuestListActionDropdownFragment.graphql"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import { DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface OrganizationGuestListActionDropdownProps extends TestIDProps {
  testid?: string
  guestMembershipKey: OrganizationGuestListActionDropdownFragment$key
  email: string
}

function OrganizationGuestListActionDropdown(
  OrganizationGuestListActionDropdownProps: OrganizationGuestListActionDropdownProps
) {
  const {
    testid = "OrganizationGuestListActionDropdown",
    guestMembershipKey,
    email,
  } = OrganizationGuestListActionDropdownProps

  const guestMembership = useFragment<OrganizationGuestListActionDropdownFragment$key>(
    graphql`
      fragment OrganizationGuestListActionDropdownFragment on GuestMembership {
        id
        email
        user {
          id
          fullName
        }
      }
    `,
    guestMembershipKey
  )

  const guest: GuestCheckList = {
    guestMembershipId: guestMembership.id,
    email,
    userId: guestMembership.user.id,
  }
  return (
    <DiscoMoreActionsDropdown testid={testid}>
      <MessageCommunityGuestsButton guestMemberships={[guest]}>
        {(buttonProps) => (
          <DiscoDropdownItem
            key={`${testid}.message`}
            {...buttonProps}
            testid={`${testid}.message`}
            title={"Send Email"}
          />
        )}
      </MessageCommunityGuestsButton>
      <CreateInvitationButton givenEmails={email ? [email] : undefined}>
        {({ onClick }) => (
          <DiscoDropdownItem
            onClick={onClick}
            testid={`${testid}.invite`}
            title={`Invite to Community`}
          />
        )}
      </CreateInvitationButton>

      {guestMembershipKey && (
        <RemoveCommunityGuestButton memberships={[guest]}>
          {(props) => (
            <DiscoDropdownItem
              {...props}
              testid={`${testid}.remove`}
              title={
                <DiscoText color={"error.main"} variant={"body-sm"}>
                  {"Remove Guest"}
                </DiscoText>
              }
            />
          )}
        </RemoveCommunityGuestButton>
      )}
    </DiscoMoreActionsDropdown>
  )
}

export default OrganizationGuestListActionDropdown
