import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import MessageCommunityMembersEmailFormFields from "@/organization/people/actions/message/MessageCommunityMembersEmailFormFields"
import {
  MessageCommunityGuestsDrawerMutation,
  SendGuestMessageInput,
} from "@/organization/people/actions/message/__generated__/MessageCommunityGuestsDrawerMutation.graphql"
import { MessageCommunityGuestsDrawerPreview_EmailMutation } from "@/organization/people/actions/message/__generated__/MessageCommunityGuestsDrawerPreview_EmailMutation.graphql"
import CommunityGuestCheckList from "@/organization/people/member-checklist/CommunityGuestCheckList"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoDrawer,
  DiscoDrawerHeader,
  DiscoFormControl,
  DiscoText,
} from "@disco-ui"
import DiscoMacrosTooltip from "@disco-ui/tooltip/DiscoMacrosTooltip"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql } from "relay-runtime"

export type MessageCommunityGuestsFormState = Omit<
  SendGuestMessageInput,
  "organizationMembershipIds"
> & {
  memberships: GuestCheckList[]
}
export type MessageCommunityGuestsFormStore = FormStore<
  MessageCommunityGuestsFormState,
  MessageCommunityGuestsDrawerMutation
>

interface Props extends TestIDProps {
  isOpen: boolean
  onClose: () => void
  onMessage?: () => void
  memberships: GuestCheckList[]
  zIndex?: React.CSSProperties["zIndex"]
}

function MessageCommunityGuestsDrawer({
  isOpen,
  onClose,
  onMessage,
  memberships,
  zIndex,
  testid,
}: Props) {
  const classes = useStyles()
  const { authUser } = useAuthUser({ required: true })
  const activeOrganization = useActiveOrganization()!

  const previewEmailForm =
    useFormStore<MessageCommunityGuestsDrawerPreview_EmailMutation>(
      graphql`
        mutation MessageCommunityGuestsDrawerPreview_EmailMutation(
          $input: PreviewSendMessageEmailInput!
        ) {
          response: previewSendMessageEmail(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `,
      {
        organizationId: activeOrganization.id,
        subject: "",
        richEditorBody: "",
      }
    )

  const form = useFormStore<
    MessageCommunityGuestsDrawerMutation,
    MessageCommunityGuestsFormState
  >(
    graphql`
      mutation MessageCommunityGuestsDrawerMutation($input: SendGuestMessageInput!) {
        response: sendGuestMessage(input: $input) {
          automation: node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      memberships,
      emailMessageInput: {
        subject: "",
        richEditorBody: JSON.stringify(EditorUtils.createParagraphs([""])),
      },
      guestMembershipIds: [],
    }
  )

  useEffect(() => {
    if (!memberships || !form.state.memberships) return
    if (isOpen) {
      form.state.memberships.replace(memberships)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <DiscoDrawer
      open={isOpen}
      onClose={onClose}
      size={"medium"}
      containerClasses={{
        drawerContainer: classes.drawer,
      }}
      shouldCloseOnEsc={false}
      zIndex={zIndex}
    >
      <DiscoDrawerHeader
        className={classes.drawerHeader}
        title={"Write Message"}
        disableExpand
        onClose={onClose}
      />
      <Form
        id={"MessageCommunityGuestsForm"}
        testid={"MessageCommunityGuestsForm"}
        onSubmit={handleSendMessage}
        classes={{ formFieldsContainer: classes.form, buttonsRoot: classes.buttons }}
        hasStickyButtons
        height={"100%"}
        buttons={
          <>
            <DiscoMacrosTooltip
              usePlace={"your messages"}
              macros={["user.firstName", "user.lastName"]}
            />

            <DiscoButton
              onClick={handlePreviewButtonClick}
              color={"transparent"}
              leftIcon={"send"}
              testid={`${testid}.preview`}
            >
              {"Send Me Preview"}
            </DiscoButton>

            <DiscoButton
              onClick={handleSendMessage}
              testid={`${testid}.confirm`}
              shouldDisplaySpinner={form.isSubmitting}
              style={{ marginLeft: "auto" }}
            >
              {"Send Message"}
            </DiscoButton>
          </>
        }
      >
        <CommunityGuestCheckList isCollapsible form={form} memberships={memberships} />

        <MessageCommunityMembersEmailFormFields form={form} />
        <>
          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Message"}
              </DiscoText>
            }
            error={Boolean(form.errorsByField.richEditorBody)}
            errorMessages={form.errorsByField.richEditorBody}
            marginBottom={0}
          >
            <DiscoEditor
              placeholder={"Write your message here"}
              defaultValue={form.state.emailMessageInput?.richEditorBody}
              onChange={(v) => {
                form.state.emailMessageInput!.richEditorBody = v
              }}
              minHeight={200}
              testid={`MessageCommunityGuestsForm.rich-editor`}
              config={"email"}
              showOutline
            />
          </DiscoFormControl>
        </>
      </Form>
    </DiscoDrawer>
  )

  async function handleSendMessage() {
    const guestMembershipIds = form.state.memberships?.map((m) => m.guestMembershipId)

    const { didSave } = await form.submit({
      organizationId: activeOrganization.id,
      guestMembershipIds,
      emailMessageInput: getEmailInput(),
    })
    if (!didSave) return

    const totalMembersToMessage = guestMembershipIds.length

    const label = totalMembersToMessage === 1 ? "Guest" : "Guests"

    displaySuccessToast({
      message: `Sent message to ${totalMembersToMessage} ${label}`,
      testid: `${testid}.success-toast`,
    })

    onClose()
    onMessage?.()
  }

  function handlePreviewButtonClick() {
    handleSendEmailPreview()
  }

  async function handleSendEmailPreview() {
    const { didSave: didSend } = await previewEmailForm.submit({
      organizationId: activeOrganization.id,
      ...getEmailInput(),
    })

    if (!didSend) return

    displaySuccessToast({
      message: `Preview email sent to ${authUser.email}`,
      testid: `${testid}.preview.success-toast`,
    })
  }

  function getEmailInput() {
    return {
      subject: form.state.emailMessageInput?.subject || "",
      richEditorBody: form.state.emailMessageInput?.richEditorBody || "",
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawer: {
    padding: 0,
    // Hiding overflow so MessageCommunityGuestsForm can use sticky footer
    overflowY: "hidden",
  },
  drawerHeader: {
    padding: theme.spacing(2.5, 2.5, 0, 2.5),
  },
  form: {
    display: "grid",
    gap: theme.spacing(2.5),
    alignContent: "start",
    padding: theme.spacing(2.5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
}))

export default observer(MessageCommunityGuestsDrawer)
