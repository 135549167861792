import MessageCommunityMembersDrawerContent, {
  SendMessageToMembers,
} from "@/organization/people/actions/message/MessageCommunityMembersDrawerContent"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  isOpen: boolean
  onClose: () => void
  onMessage?: () => void
  sendTo: SendMessageToMembers
  zIndex?: React.CSSProperties["zIndex"]
}

function MessageCommunityMembersDrawer({
  testid,
  isOpen,
  onClose,
  onMessage,
  sendTo,
  zIndex,
}: Props) {
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={isOpen}
      onClose={onClose}
      size={"medium"}
      containerClasses={{
        drawerContainer: classes.drawer,
      }}
      shouldCloseOnEsc={false}
      zIndex={zIndex}
    >
      <DiscoDrawerHeader
        className={classes.drawerHeader}
        title={"Write Message"}
        disableExpand
        onClose={onClose}
      />
      {isOpen && (
        <MessageCommunityMembersDrawerContent
          testid={testid}
          onClose={onClose}
          onMessage={onMessage}
          sendTo={sendTo}
        />
      )}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawer: {
    padding: 0,
    // Hiding overflow so MessageCommunityMembersForm can use sticky footer
    overflowY: "hidden",
  },
  drawerHeader: {
    padding: theme.spacing(2.5, 2.5, 0, 2.5),
  },
}))

export default MessageCommunityMembersDrawer
