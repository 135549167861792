/**
 * @generated SignedSource<<841eb92005036afb30260a52401f468a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreviewSendMessageEmailInput = {
  organizationId: string;
  productId?: string | null;
  occurrenceId?: string | null;
  subject: string;
  richEditorBody: string;
};
export type MessageCommunityGuestsDrawerPreview_EmailMutation$variables = {
  input: PreviewSendMessageEmailInput;
};
export type MessageCommunityGuestsDrawerPreview_EmailMutation$data = {
  readonly response: {
    readonly data: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type MessageCommunityGuestsDrawerPreview_EmailMutation = {
  variables: MessageCommunityGuestsDrawerPreview_EmailMutation$variables;
  response: MessageCommunityGuestsDrawerPreview_EmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreviewSendMessageEmailResponse",
    "kind": "LinkedField",
    "name": "previewSendMessageEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCommunityGuestsDrawerPreview_EmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageCommunityGuestsDrawerPreview_EmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c2ba4f08b3aacd3af13b4f163ff9b05f",
    "id": null,
    "metadata": {},
    "name": "MessageCommunityGuestsDrawerPreview_EmailMutation",
    "operationKind": "mutation",
    "text": "mutation MessageCommunityGuestsDrawerPreview_EmailMutation(\n  $input: PreviewSendMessageEmailInput!\n) {\n  response: previewSendMessageEmail(input: $input) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b46224271c2c0a5fc98d6dbc7165d64b";

export default node;
