import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"
import MessageCommunityMembersDrawer from "./MessageCommunityMembersDrawer"
import { MessageProductMembersButtonQuery } from "./__generated__/MessageProductMembersButtonQuery.graphql"

interface Props extends TestIDProps {
  children: OverridableDiscoButtonChildren
  memberships?: { id: GlobalID; memberId: string }[]
  isAllSelected?: boolean
  onMessage?: () => void
  zIndex?: React.CSSProperties["zIndex"]
  selectedGroupFilter?: "unassigned" | GlobalID
  className?: string
}

function MessageProductMembersButton({
  children,
  memberships: productMemberships = [],
  isAllSelected = false,
  selectedGroupFilter,
  onMessage,
  testid = "MessageProductMembersButton",
  zIndex,
  ...rest
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const activeProduct = useActiveProduct()
  const isSendingToAll = isAllSelected && !selectedGroupFilter

  const { product } = useLazyLoadQuery<MessageProductMembersButtonQuery>(
    graphql`
      query MessageProductMembersButtonQuery(
        $id: ID!
        $productMemberships: [ID!]
        $memberGroupFilter: MemberGroupFilterInput
        $skip: Boolean!
      ) {
        product: node(id: $id) @skip(if: $skip) {
          ... on Product {
            productMemberships(
              ids: $productMemberships
              memberGroupFilterInput: $memberGroupFilter
            ) {
              edges {
                node {
                  id
                  organizationMembershipId
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeProduct?.id || "",
      productMemberships: productMemberships.map((m) => m.id),
      memberGroupFilter:
        selectedGroupFilter === "unassigned"
          ? { unassigned: true }
          : { memberGroupId: selectedGroupFilter },
      skip: isSendingToAll,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const memberships = Relay.connectionToArray(product?.productMemberships)

  return (
    <>
      <OverridableDiscoButton {...rest} testid={testid} onClick={onOpen}>
        {children}
      </OverridableDiscoButton>

      <MessageCommunityMembersDrawer
        testid={testid}
        isOpen={isOpen}
        onClose={onClose}
        onMessage={onMessage}
        sendTo={isSendingToAll ? "product" : memberships}
        zIndex={zIndex}
      />
    </>
  )
}

function MessageProductMembersButtonSkeleton(props: Props) {
  return <DiscoButtonSkeleton className={props.className} />
}

export default Relay.withSkeleton<Props>({
  component: observer(MessageProductMembersButton),
  skeleton: MessageProductMembersButtonSkeleton,
})
