import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ContentNotificationEmailCtaButton } from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { FeedSelection } from "@/post/FeedSelectorDropdown"
import { PostPreviewEmailFormFieldsFragment$key } from "@/post/share/__generated__/PostPreviewEmailFormFieldsFragment.graphql"
import { PostPreviewEmailFormFieldsQuery } from "@/post/share/__generated__/PostPreviewEmailFormFieldsQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoFormControl,
  DiscoInput,
  DiscoSection,
  DiscoSpinner,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import { format } from "date-fns"
import { observer } from "mobx-react-lite"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
interface PostPreviewEmailFormFieldsProps extends TestIDProps {
  postKey?: PostPreviewEmailFormFieldsFragment$key | null
  form: {
    state: {
      selectedFeed: FeedSelection
      isScheduled?: boolean
      content: {
        name?: string | null
        notificationEmailSubject?: string | null
        richEditorNotificationEmailBodyContent?: string | null
        sendNotificationEmail?: boolean | null | undefined
        notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null
        createdById?: string | null
      }
    }
  }
}

function PostPreviewEmailFormFields({
  form,
  postKey = null,
  testid = "PostPreviewEmailFormFields",
}: PostPreviewEmailFormFieldsProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  // post exists if user is editing a scheduled post
  // or sharing a published post
  // otherwise, user is creating a scheduled post
  const post = useFragment<PostPreviewEmailFormFieldsFragment$key>(
    graphql`
      fragment PostPreviewEmailFormFieldsFragment on Post {
        id
        feedId
        product {
          id
          name
          slug
        }
        content {
          id
          notificationEmailSentDatetime
        }
      }
    `,
    postKey
  )

  // if user is previewing a scheduled post they're creating, need to access productId from form store, otherwise post record will exist
  const productId = form.state.selectedFeed.product?.id || post?.product?.id || ""

  const { preview } = useLazyLoadQuery<PostPreviewEmailFormFieldsQuery>(
    graphql`
      query PostPreviewEmailFormFieldsQuery(
        $richEditorBody: JSON!
        $params: JSON
        $entityId: ID!
      ) {
        preview: previewEmailTemplate(
          input: {
            entityId: $entityId
            emailTemplate: NewAnnouncementNotification
            richEditorBody: $richEditorBody
            params: $params
          }
        ) {
          data
          errors {
            field
            message
          }
        }
      }
    `,
    {
      entityId: productId || activeOrganization.id,
      richEditorBody: form.state.content.richEditorNotificationEmailBodyContent || "",
      params: JSON.stringify({
        title: form.state.content.name,
        notificationEmailCtaButton: form.state.content.notificationEmailCtaButton,
        // if previewing an existing post, show the author of the post, who may not be the user previewing the email
        userId: form.state.content.createdById,
      }),
    }
  )

  return (
    <>
      <DiscoSection padding={0} marginBottom={2.5}>
        {form.state.isScheduled ? (
          <DiscoSwitch
            testid={`${testid}.email-toggle`}
            labelPlacement={"end"}
            checked={form.state.content.sendNotificationEmail ?? undefined}
            onChange={(v) => (form.state.content.sendNotificationEmail = v)}
            label={
              "Share post automatically by email when published on the scheduled date."
            }
          />
        ) : post?.content.notificationEmailSentDatetime ? (
          <DiscoAlert severity={"warning"} message={getMessage()} />
        ) : (
          <DiscoText>{getMessage()}</DiscoText>
        )}
      </DiscoSection>
      {form.state.content.sendNotificationEmail && (
        <>
          <DiscoFormControl label={"Email Subject"}>
            <DiscoInput
              inputProps={{ "data-testid": `${testid}.subject-line` }}
              placeholder={"An eye-catching subject line"}
              value={form.state.content.notificationEmailSubject}
              onChange={(e) =>
                (form.state.content.notificationEmailSubject = e.target.value)
              }
            />
          </DiscoFormControl>
          <DiscoFormControl label={"Email Body Preview"}>
            <DiscoSection padding={0} className={classes.emailContainer}>
              {preview?.data ? (
                <div
                  data-testid={`${testid}.preview-pane`}
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: preview.data }}
                />
              ) : (
                <DiscoText>{"Failed to retrieve email preview."}</DiscoText>
              )}
            </DiscoSection>
          </DiscoFormControl>
        </>
      )}
    </>
  )

  function getMessage() {
    if (post?.content.notificationEmailSentDatetime) {
      return (
        <>
          {`Are you sure you would like to share this post? This post has already been shared by email on ${format(
            new Date(post.content.notificationEmailSentDatetime),
            DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT
          )}`}
        </>
      )
    }
    return "Sharing by email is a great way to encourage engagement in your community. The email will go to everyone who has access to this post."
  }
}
const useStyles = makeUseStyles({
  emailContainer: {
    overflow: "hidden",
    pointerEvents: "none",
    cursor: "default",
  },
})

export default Relay.withSkeleton({
  component: observer(PostPreviewEmailFormFields),
  skeleton: () => <DiscoSpinner absoluteCenter />,
})
