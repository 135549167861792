import FormStore from "@/core/form/store/FormStore"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText } from "@disco-ui"
import { IconButton } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

export type GuestCheckListFormState = {
  memberships: GuestCheckList[]
}

export type GuestCheckListFormStore = FormStore<GuestCheckListFormState>

interface CommunityGuestCheckListTitleProps extends TestIDProps {
  form: GuestCheckListFormStore
  isCollapsible: boolean
  isExpanded: boolean
  onToggle: () => void
}

function CommunityGuestCheckListTitle({
  testid = "CommunityGuestCheckListTitle",
  form,
  isCollapsible,
  isExpanded,
  onToggle,
}: CommunityGuestCheckListTitleProps) {
  const classes = useStyles({
    isCollapsible,
    isExpanded,
  })

  return (
    <div className={classes.titleContainer}>
      {isCollapsible && (
        <IconButton
          data-testid={`${testid}.expandButton`}
          className={classes.expandButton}
          onClick={onToggle}
        >
          <ChevronIcon width={24} height={24} />
        </IconButton>
      )}
      {renderTitle()}
    </div>
  )

  function renderTitle() {
    if (!isCollapsible)
      return (
        <DiscoText
          className={classes.text}
          variant={"body-xs-uppercase"}
        >{`Selected Guests`}</DiscoText>
      )

    const numberOfRecipients = form.state.memberships.length

    return (
      <DiscoText className={classes.text} variant={"body-sm"}>
        {"Directly send bulk message to "}
        <DiscoText component={"span"} variant={"body-sm-700"}>
          {`${numberOfRecipients} selected ${
            numberOfRecipients === 1 ? "Guest" : "Guests"
          }`}
        </DiscoText>
      </DiscoText>
    )
  }
}

type StyleProps = {
  isCollapsible?: boolean
  isExpanded: boolean
}

const useStyles = makeUseStyles((theme) => ({
  text: (props: StyleProps) => ({
    padding: theme.spacing(2),

    ...styleIf(props.isCollapsible, {
      padding: theme.spacing(2, 2, 2, 1),
    }),
  }),
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  expandButton: (props: StyleProps) => ({
    marginLeft: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(0.5),

    "& svg": {
      transition: "transform 0.2s ease-in-out",
      transform: "rotate(90deg)",

      ...styleIf(props.isExpanded, {
        transform: "rotate(180deg)",
      }),
    },
  }),
}))

export default observer(CommunityGuestCheckListTitle)
