import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import CommunityMembersCheckListTitle, {
  AllPropertiesMemberChecklist,
  MemberCheckListFormStore,
  OrganizationMembershipIdMemberChecklist,
} from "@/organization/people/member-checklist/CommunityMembersCheckListTitle"
import { CommunityMembersCheckListPaginationQuery } from "@/organization/people/member-checklist/__generated__/CommunityMembersCheckListPaginationQuery.graphql"
import { CommunityMembersCheckListQuery } from "@/organization/people/member-checklist/__generated__/CommunityMembersCheckListQuery.graphql"
import { CommunityMembersCheckList_PaginationFragment$key } from "@/organization/people/member-checklist/__generated__/CommunityMembersCheckList_PaginationFragment.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoCheckbox,
  DiscoCheckboxSkeleton,
  DiscoDivider,
  DiscoSpinner,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Collapse } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const MEMBERS_PER_PAGE = 10

interface CommunityMembersCheckListProps extends TestIDProps {
  form: MemberCheckListFormStore
  memberships: OrganizationMembershipIdMemberChecklist[]
  isCollapsible?: boolean
}

function CommunityMembersCheckList({
  testid = "CommunityMembersCheckList",
  form,
  isCollapsible,
  memberships,
}: CommunityMembersCheckListProps) {
  const activeOrganization = useActiveOrganization()!

  const { isOpen, onToggle } = useDisclosure()

  const classes = useStyles({
    isCollapsible,
    isExpanded: isOpen,
  })

  const { authUser } = useAuthUser({ required: true })

  const { organization } = useLazyLoadQuery<CommunityMembersCheckListQuery>(
    graphql`
      query CommunityMembersCheckListQuery(
        $id: ID!
        $organizationMembershipIds: [ID!]
        $orderBy: OrganizationMembershipsOrderBy
        $direction: OrderDirection
        $first: Int
        $after: String
        $last: Int
        $before: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...CommunityMembersCheckList_PaginationFragment
              @arguments(
                organizationMembershipIds: $organizationMembershipIds
                orderBy: $orderBy
                direction: $direction
                first: $first
                after: $after
                last: $last
                before: $before
              )
          }
        }
      }
    `,
    {
      first: MEMBERS_PER_PAGE,
      id: activeOrganization.id,
      orderBy: "member_name",
      direction: "ASC",
      organizationMembershipIds: memberships.map((m) => m.organizationMembershipId),
    }
  )

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    CommunityMembersCheckListPaginationQuery,
    CommunityMembersCheckList_PaginationFragment$key
  >(
    graphql`
      fragment CommunityMembersCheckList_PaginationFragment on Organization
      @refetchable(queryName: "CommunityMembersCheckListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        organizationMembershipIds: { type: "[ID!]" }
        orderBy: { type: "OrganizationMembershipsOrderBy" }
        direction: { type: "OrderDirection" }
      ) {
        organizationMemberships(
          organizationMembershipIds: $organizationMembershipIds
          orderBy: $orderBy
          direction: $direction
          excludeViewer: true
          first: $first
          after: $after
          last: $last
          before: $before
        ) @connection(key: "CommunityMembersCheckList__organizationMemberships") {
          edges {
            node {
              id
              email
              role
              member {
                id
                ...ProfileAvatarWithDetailsFragment
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const organizationMemberships = Relay.connectionToArray(data?.organizationMemberships)

  const viewerMembershipId = activeOrganization.viewerMembership!.id
  // find the viewer in the original list of memberships
  const viewerMembership = memberships.find(
    (m) => m.organizationMembershipId === viewerMembershipId
  )
  const includesViewer = !!viewerMembership
  const isViewerSelected = form.state.memberships.some(
    (m) => m.organizationMembershipId === viewerMembershipId
  )

  const viewerTestId = `${testid}.member.${authUser.email}`

  return (
    <div className={classes.container} data-testid={testid}>
      <CommunityMembersCheckListTitle
        testid={`${testid}.title`}
        form={form}
        isCollapsible={Boolean(isCollapsible)}
        isExpanded={isOpen}
        onToggle={onToggle}
      />
      <Collapse in={isCollapsible ? isOpen : true} className={classes.collapseArea}>
        <div className={classes.listContainer}>
          {includesViewer && (
            <>
              <DiscoText variant={"body-xs"}>
                {"You have selected your own account."}
              </DiscoText>
              <div className={classes.memberItem}>
                <DiscoCheckbox
                  label={undefined}
                  onChange={() =>
                    toggleSelected({
                      organizationMembershipId: viewerMembershipId,
                      userId: authUser.id,
                      email: authUser.email,
                      role: activeOrganization.viewerMembership!.role,
                    })
                  }
                  checked={isViewerSelected}
                  testid={`${viewerTestId}.checkbox`}
                />
                <ProfileAvatarWithDetails
                  userKey={authUser}
                  details={authUser.email}
                  classes={{ details: classes.memberDetails }}
                  testid={viewerTestId}
                  linkToProfile={false}
                />
              </div>
              {memberships.length > 1 && <DiscoDivider />}
            </>
          )}

          {organizationMemberships.map((om) => {
            const isChecked = form.state.memberships.some(
              (m) => m.organizationMembershipId === om.id
            )
            const omTestId = `${testid}.member.${om.email}`

            return (
              <div key={om.id} className={classes.memberItem}>
                <DiscoCheckbox
                  label={undefined}
                  onChange={() =>
                    toggleSelected({
                      organizationMembershipId: om.id,
                      userId: om.member.id,
                      email: om.email || "",
                      role: om.role,
                    })
                  }
                  checked={isChecked}
                  testid={`${omTestId}.checkbox`}
                />
                <ProfileAvatarWithDetails
                  userKey={om.member}
                  details={om.email}
                  classes={{ details: classes.memberDetails }}
                  testid={omTestId}
                  linkToProfile={false}
                />
              </div>
            )
          })}
          {hasNext && (
            <DiscoScrolledIntoView
              isLoading={isLoadingNext}
              onScrolledIntoView={() => loadNext(MEMBERS_PER_PAGE)}
              skeleton={
                <div className={classes.spinnerContainer}>
                  <DiscoSpinner size={"sm"} />
                </div>
              }
            />
          )}
        </div>
      </Collapse>
    </div>
  )

  function toggleSelected(membership?: AllPropertiesMemberChecklist) {
    if (!membership) return
    const isChecked = form.state.memberships.some(
      (m) => m.organizationMembershipId === membership.organizationMembershipId
    )

    if (isChecked)
      form.state.memberships.replace(
        form.state.memberships.filter(
          (m) => m.organizationMembershipId !== membership.organizationMembershipId
        )
      )
    else form.state.memberships.push(membership)
  }
}

type StyleProps = {
  isCollapsible?: boolean
  isExpanded: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: (props: StyleProps) => ({
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.big,
    ...styleIf(!props.isCollapsible, {
      display: "grid",
      justifyItems: "start",
    }),
  }),
  listContainer: {
    width: "100%",
    display: "grid",
    padding: theme.spacing(2),
    paddingTop: 0,
    gap: theme.spacing(1.5),
    maxHeight: "300px",
    overflowY: "auto",
  },
  memberItem: {
    display: "flex",
  },
  memberDetails: {
    textAlign: "start",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  collapseArea: {
    width: "100%",
  },
}))

export function CommunityMembersCheckListSkeleton({
  isCollapsible,
  form,
}: Partial<CommunityMembersCheckListProps>) {
  const { isOpen, onToggle } = useDisclosure()

  const classes = useStyles({
    isCollapsible,
    isExpanded: isOpen,
  })

  return (
    <div className={classes.container}>
      {form ? (
        <CommunityMembersCheckListTitle
          form={form}
          isCollapsible={Boolean(isCollapsible)}
          isExpanded={isOpen}
          onToggle={onToggle}
        />
      ) : (
        <DiscoTextSkeleton width={"350px"} margin={2} />
      )}
      <Collapse in={isCollapsible ? isOpen : true}>
        <div className={classes.listContainer}>
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
          <DiscoCheckboxSkeleton />
        </div>
      </Collapse>
    </div>
  )
}

export default Relay.withSkeleton<CommunityMembersCheckListProps>({
  skeleton: CommunityMembersCheckListSkeleton,
  component: observer(CommunityMembersCheckList),
})
