import { PinPostButtonMutation } from "@/post/pin/__generated__/PinPostButtonMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButtonSkeleton, DiscoIcon } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { graphql, useMutation } from "react-relay"

interface PinPostButtonProps {
  refetch: VoidFunction
  postId: GlobalID
  feedId?: GlobalID
  dashboardId?: GlobalID
  children?: OverridableDiscoButtonChildren
}

function PinPostButton({
  refetch,
  postId,
  feedId,
  dashboardId,
  children,
}: PinPostButtonProps) {
  if (!feedId && !dashboardId)
    throw new Error("Must pass one of either feedId or dashboardId")

  const [commit, isPinning] = useMutation<PinPostButtonMutation>(graphql`
    mutation PinPostButtonMutation($input: PinPostInput!, $feedId: ID, $dashboardId: ID) {
      pinPost(input: $input) {
        node {
          id
          pinnedPost(feedId: $feedId, dashboardId: $dashboardId) {
            id
          }
        }
      }
    }
  `)

  return (
    <OverridableDiscoButton
      leftIcon={<DiscoIcon icon={"pin"} />}
      data-testid={"PinPostButton"}
      onClick={handlePinPost}
      shouldDisplaySpinner={isPinning}
      disabled={isPinning}
      stopPropagation
    >
      {children || "Pin"}
    </OverridableDiscoButton>
  )

  function handlePinPost() {
    commit({
      variables: {
        input: {
          postId,
          feedId,
          dashboardId,
        },
        feedId,
        dashboardId,
      },
      onCompleted() {
        refetch()
        displaySuccessToast({ message: "Post pinned!" })
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}

export function PinPostButtonSkeleton() {
  return <DiscoButtonSkeleton />
}

export default Relay.withSkeleton({
  component: PinPostButton,
  skeleton: PinPostButtonSkeleton,
})
