/**
 * @generated SignedSource<<df33b8881138ff20b6117ee784942237>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PreviewSendMessageEmailInput = {
  organizationId: string;
  productId?: string | null;
  occurrenceId?: string | null;
  subject: string;
  richEditorBody: string;
};
export type MessageCommunityMembersDrawerContentPreview_EmailMutation$variables = {
  input: PreviewSendMessageEmailInput;
};
export type MessageCommunityMembersDrawerContentPreview_EmailMutation$data = {
  readonly response: {
    readonly data: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type MessageCommunityMembersDrawerContentPreview_EmailMutation = {
  variables: MessageCommunityMembersDrawerContentPreview_EmailMutation$variables;
  response: MessageCommunityMembersDrawerContentPreview_EmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PreviewSendMessageEmailResponse",
    "kind": "LinkedField",
    "name": "previewSendMessageEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCommunityMembersDrawerContentPreview_EmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageCommunityMembersDrawerContentPreview_EmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ac3651b34fcd8446d7a26247ffb6a43",
    "id": null,
    "metadata": {},
    "name": "MessageCommunityMembersDrawerContentPreview_EmailMutation",
    "operationKind": "mutation",
    "text": "mutation MessageCommunityMembersDrawerContentPreview_EmailMutation(\n  $input: PreviewSendMessageEmailInput!\n) {\n  response: previewSendMessageEmail(input: $input) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2995ca10eae7c8a9f3c173e084566a7b";

export default node;
