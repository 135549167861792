import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoCheckbox, DiscoFormControl, DiscoInput } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import type { StripeElementStyle } from "@stripe/stripe-js"
import classNames from "classnames"
import { initialProductPaymentFormState } from "../../payment/ProductPaymentFormConstants"

interface CardFormProps {
  cardFormState: typeof initialProductPaymentFormState.cardFormState
  onSaveCardCheckboxSelect?: () => void
  onPostalCodeChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onCardHolderNameChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  customClassName?: string
}

function CardForm({
  customClassName,
  cardFormState,
  onCardHolderNameChange,
  onPostalCodeChange,
  onSaveCardCheckboxSelect,
}: CardFormProps) {
  const { authUser } = useAuthUser()

  const disableSaveCardCheckbox = !onSaveCardCheckboxSelect

  const theme = useTheme()

  // See the stripeJs.StripeElementStyle for options
  // We needed create style separately because Stripe elements comes with an initial style, it's not customizable with `customClassName`
  const CARD_ELEMENT_STYLE_OPTIONS: StripeElementStyle = {
    base: {
      lineHeight: "48px",

      backgroundColor: theme.palette.groovy.neutral[100],
      color: theme.palette.text.primary,

      fontSize: "14px",
      fontSmoothing: "antialiased",
      fontWeight: "normal",

      "::placeholder": {
        color: theme.palette.groovy.grey[300],
      },
    },
    invalid: {
      color: theme.palette.error.main,
    },
  }

  const classes = useStyles()

  // TODO: convert this to Form, pass onSubmit and errorInfo from parent
  return (
    <div className={classNames(classes.container, customClassName)}>
      <DiscoFormControl label={"Card Number"} testid={"card-form.card-number"}>
        <CardNumberElement
          options={{
            style: CARD_ELEMENT_STYLE_OPTIONS,
            placeholder: "XXXX XXXX XXXX XXXX",
            showIcon: true,
          }}
        />
      </DiscoFormControl>

      <DiscoFormControl label={"Holder Name"}>
        <DiscoInput
          data-testid={"CardForm.holder-name"}
          className={classes.fullName}
          value={cardFormState.cardHolderName}
          name={"holder-name"}
          onChange={onCardHolderNameChange}
          placeholder={"Full Name"}
          size={"large"}
          fullWidth
        />
      </DiscoFormControl>

      <div className={classes.cardInfoSection}>
        <DiscoFormControl label={"Expiration Date"} testid={"card-form.exp-date"}>
          <CardExpiryElement options={{ style: CARD_ELEMENT_STYLE_OPTIONS }} />
        </DiscoFormControl>

        <DiscoFormControl label={"CVV"} testid={"card-form.card-cvc"}>
          <CardCvcElement
            options={{ style: CARD_ELEMENT_STYLE_OPTIONS, placeholder: "3 Digits" }}
          />
        </DiscoFormControl>
      </div>

      <DiscoFormControl label={"Zip or Postal Code"}>
        <DiscoInput
          data-testid={"CardForm.postal-code"}
          value={cardFormState.postalCode}
          name={"postal-code"}
          onChange={onPostalCodeChange}
          size={"large"}
          fullWidth
        />
      </DiscoFormControl>

      {authUser && !disableSaveCardCheckbox && (
        <DiscoCheckbox
          className={"product-payment-modal__agreement-checkbox"}
          label={"Save this card for future purchases"}
          checked={cardFormState.isSaveCardCheckboxSelected}
          onChange={onSaveCardCheckboxSelect}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    maxWidth: "350px",

    "& .StripeElement": {
      backgroundColor: theme.palette.groovy.neutral[100],
      borderRadius: `${theme.measure.borderRadius.big}px !important`,
      padding: theme.spacing(0, 1.5),
    },
  },
  fullName: {
    "&:focus-within, &:focus, &:hover": {
      borderColor: "transparent",
      outline: "none",

      "& .input:not([disabled])": {
        border: "none",
      },
    },
  },
  cardInfoSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      gridTemmplateColumns: "1fr",
      gap: theme.spacing(1),
    },
  },
}))

export default CardForm
