/**
 * @generated SignedSource<<f7d810bb34cc2e40270f389bd45145ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type SharePostInput = {
  postId: string;
  emailSubject: string;
  notificationEmailCtaButton: ContentNotificationEmailCtaButton;
};
export type SharePostModalMutation$variables = {
  input: SharePostInput;
};
export type SharePostModalMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly feed: {
        readonly id: string;
        readonly name: string;
        readonly product: {
          readonly id: string;
          readonly name: string;
          readonly status: ProductStatus;
          readonly type: ProductType;
          readonly startDate: string | null;
          readonly waitingRoomEndsAt: string | null;
          readonly slug: string;
        } | null;
      };
      readonly content: {
        readonly id: string;
        readonly name: string | null;
        readonly sendNotificationEmail: boolean | null;
        readonly notificationEmailSubject: string | null;
        readonly richEditorDescriptionContent: string | null;
        readonly richEditorNotificationEmailBodyContent: string | null;
        readonly notificationEmailCtaButton: ContentNotificationEmailCtaButton;
      };
      readonly " $fragmentSpreads": FragmentRefs<"PostPreviewEmailFormFieldsFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type SharePostModalMutation = {
  variables: SharePostModalMutation$variables;
  response: SharePostModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Feed",
  "kind": "LinkedField",
  "name": "feed",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingRoomEndsAt",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendNotificationEmail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationEmailSubject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorDescriptionContent",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorNotificationEmailBodyContent",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationEmailCtaButton",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SharePostModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "SharePostResponse",
        "kind": "LinkedField",
        "name": "sharePost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Post",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PostPreviewEmailFormFieldsFragment"
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SharePostModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "SharePostResponse",
        "kind": "LinkedField",
        "name": "sharePost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Post",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notificationEmailSentDatetime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04def6a5ee3d8c5ed1b1b0a77b8531c2",
    "id": null,
    "metadata": {},
    "name": "SharePostModalMutation",
    "operationKind": "mutation",
    "text": "mutation SharePostModalMutation(\n  $input: SharePostInput!\n) {\n  response: sharePost(input: $input) {\n    node {\n      id\n      feed {\n        id\n        name\n        product {\n          id\n          name\n          status\n          type\n          startDate\n          waitingRoomEndsAt\n          slug\n        }\n      }\n      content {\n        id\n        name\n        sendNotificationEmail\n        notificationEmailSubject\n        richEditorDescriptionContent\n        richEditorNotificationEmailBodyContent\n        notificationEmailCtaButton\n      }\n      ...PostPreviewEmailFormFieldsFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment PostPreviewEmailFormFieldsFragment on Post {\n  id\n  feedId\n  product {\n    id\n    name\n    slug\n  }\n  content {\n    id\n    notificationEmailSentDatetime\n  }\n}\n"
  }
};
})();

(node as any).hash = "21397cde2c6ad898fbe5b2c1ef3f31f6";

export default node;
