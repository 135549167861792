import StatusIcon from "@/core/ui/disco/icons/color-icons/status.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import MessageCommunityGuestsButton from "@/organization/people/actions/message/MessageCommunityGuestsButton"
import RemoveCommunityGuestButton from "@/organization/people/actions/remove/RemoveCommunityGuestButton"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import { DiscoButton, DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import DiscoFloatingBar from "@disco-ui/floating-bar/DiscoFloatingBar"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

interface CommunityGuestListFloatingBar {
  guests: GuestCheckList[]
  onClose: () => void
  open: boolean
}

function CommunityGuestListFloatingBar({
  guests,
  onClose,
  open,
}: CommunityGuestListFloatingBar) {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const totalCount = guests.length
  const emails = guests.map((guest) => guest.email)
  return (
    <DiscoFloatingBar
      open={open}
      onClose={onClose}
      belowModals
      testid={"CommunityGuestListFloatingBar"}
      contentClasses={{
        icon: classes.icon,
        contentButtons: classes.buttons,
        text: classes.text,
        innerContent: classes.innerContent,
        message: classes.message,
      }}
      icon={isMobile ? null : <StatusIcon width={24} height={24} />}
      content={
        isMobile ? "" : `${totalCount} ${totalCount === 1 ? "guest" : "guests"} selected`
      }
      buttons={
        <>
          <CreateInvitationButton givenEmails={emails || undefined}>
            {(buttonProps) => (
              <DiscoTooltip content={"Invite to Community"}>
                <DiscoIconButton {...buttonProps} className={classes.iconButton}>
                  <DiscoIcon icon={"user-add"} />
                </DiscoIconButton>
              </DiscoTooltip>
            )}
          </CreateInvitationButton>
          <RemoveCommunityGuestButton memberships={guests} onRemove={onClose}>
            {(buttonProps) => (
              <DiscoTooltip content={`Remove Guests`}>
                <DiscoIconButton {...buttonProps} className={classes.iconButton}>
                  <DiscoIcon icon={"trash"} />
                </DiscoIconButton>
              </DiscoTooltip>
            )}
          </RemoveCommunityGuestButton>

          <MessageCommunityGuestsButton guestMemberships={guests}>
            {(buttonProps) => (
              <DiscoButton
                classes={{ leftIcon: classes.leftIcon }}
                leftIcon={"send-01"}
                {...buttonProps}
              >
                {"Send Email"}
              </DiscoButton>
            )}
          </MessageCommunityGuestsButton>
        </>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  icon: {
    margin: 0,
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      width: 0,
    },
  },
  iconButton: {
    width: "unset",
  },
  buttons: {
    flex: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: 0,
  },
  text: {
    margin: 0,
    alignSelf: "center",
    flex: "none",
  },
  innerContent: {
    [theme.breakpoints.down("xs")]: {
      gap: 0,
    },
    gap: theme.spacing(1.5),
  },
  message: {
    [theme.breakpoints.down("xs")]: {
      gap: 0,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(0.5),
  },
}))

export default CommunityGuestListFloatingBar
