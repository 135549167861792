/**
 * @generated SignedSource<<07c47e8bad3055df9e86f604cc0656d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeletePostButtonFragment$data = {
  readonly id: string;
  readonly feedId: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "DeletePostButtonFragment";
};
export type DeletePostButtonFragment$key = {
  readonly " $data"?: DeletePostButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeletePostButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeletePostButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};

(node as any).hash = "6f5aed9f88b97aa599ce80f8873a763e";

export default node;
