import ContentFormPreviewEmailButton from "@/content/form/buttons/ContentFormPreviewEmailButton"
import ContentFormNotificationEmailCtaField from "@/content/form/sections/ContentFormNotificationEmailCtaField"
import { PostFormState } from "@/content/form/util/contentFormUtil"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import PostPreviewEmailFormFields from "@/post/share/PostPreviewEmailFormFields"
import {
  SharePostInput,
  SharePostModalMutation,
} from "@/post/share/__generated__/SharePostModalMutation.graphql"
import { SharePostModalQuery } from "@/post/share/__generated__/SharePostModalQuery.graphql"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { Grid, useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

interface SharePostModalProps extends TestIDProps {
  postId: GlobalID
  onClose?: VoidFunction
}

function SharePostModal({
  postId,
  testid = "SharePostModal",
  ...props
}: SharePostModalProps) {
  const classes = useStyles()
  const theme = useTheme()
  const memberLabel = useLabel("product_member")
  const { node } = useLazyLoadQuery<SharePostModalQuery>(
    graphql`
      query SharePostModalQuery($id: ID!) {
        node(id: $id) {
          ... on Post {
            id
            __typename
            ...usePermissionsFragment
            feed {
              id
              name
              product {
                id
                name
                status
                type
                startDate
                waitingRoomEndsAt
                slug
              }
            }
            content {
              id
              name
              sendNotificationEmail
              notificationEmailSubject
              richEditorDescriptionContent
              richEditorNotificationEmailBodyContent
              notificationEmailCtaButton
              createdBy {
                id
              }
            }
            ...PostPreviewEmailFormFieldsFragment
          }
        }
      }
    `,
    { id: postId }
  )

  const post = Relay.narrowNodeType(node, "Post")

  const form = useFormStore<
    SharePostModalMutation,
    Omit<SharePostInput, "notificationEmailCtaButton"> &
      Pick<PostFormState, "selectedFeed"> & {
        content: Pick<
          PostFormState["content"],
          | "name"
          | "sendNotificationEmail"
          | "notificationEmailSubject"
          | "richEditorNotificationEmailBodyContent"
          | "notificationEmailCtaButton"
          | "createdById"
        >
      }
  >(
    graphql`
      mutation SharePostModalMutation($input: SharePostInput!) {
        response: sharePost(input: $input) {
          node {
            id
            feed {
              id
              name
              product {
                id
                name
                status
                type
                startDate
                waitingRoomEndsAt
                slug
              }
            }
            content {
              id
              name
              sendNotificationEmail
              notificationEmailSubject
              richEditorDescriptionContent
              richEditorNotificationEmailBodyContent
              notificationEmailCtaButton
            }
            ...PostPreviewEmailFormFieldsFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      // fields required for mutation
      postId,
      emailSubject: "",
      // fields required for form fields
      content: {
        name: post?.content?.name,
        sendNotificationEmail: true,
        notificationEmailSubject: post?.content?.name
          ? `🔔 New Post - ${post.content.name}`
          : "🔔 New Post",
        // We should always be sharing the latest post content
        richEditorNotificationEmailBodyContent:
          post?.content?.richEditorDescriptionContent,
        notificationEmailCtaButton: "enabled",
        createdById: post?.content?.createdBy?.id,
      },
      selectedFeed: post!.feed!,
    },
    { requireChangeToSubmit: false }
  )

  // Safety check to prevent anyone w/o permissions from seeing the modal
  const permissions = usePermissions(post)
  if (!permissions.has("post.share") || !post) return null

  const product = post?.feed?.product

  if (product && product?.status !== "published")
    return (
      <DiscoWarningModal
        isOpen
        onClose={props.onClose}
        title={"Cannot share this Post"}
        modalContentLabel={"Cannot share this Post"}
        testid={"SharePostModal.cannot-share-warning-modal"}
        variant={"warning"}
        description={`This Experience is currently in draft mode and not visible to ${memberLabel.plural}. Publish the Experience to share this Post.`}
        cancelButtonText={"Close"}
        customConfirmationButton={
          <PublishExperienceButton width={"100%"}>
            {`Publish Experience`}
          </PublishExperienceButton>
        }
      />
    )

  return (
    <DiscoModal
      isOpen
      title={"Share Post"}
      modalContentLabel={"Share Post"}
      width={"720px"}
      maxWidth={"90vw"}
      minHeight={"600px"}
      testid={testid}
      shouldCloseOnEsc={false}
      onClose={props.onClose}
      body={
        <Form id={"SharePostForm"} testid={"SharePostForm"} onSubmit={handleSubmit}>
          <PostPreviewEmailFormFields form={form} postKey={post} />
        </Form>
      }
      classes={{ buttons: classes.buttonsOverride }}
      buttons={
        <Grid container direction={"column"}>
          <Grid item style={{ marginBottom: theme.spacing(2.5) }}>
            <ContentFormNotificationEmailCtaField form={form} contentType={"post"} />
          </Grid>
          <Grid item container alignItems={"center"} justifyContent={"space-between"}>
            <Grid item>
              <ContentFormPreviewEmailButton
                key={"preview-email"}
                form={form}
                contentType={"post"}
                productId={post.feed.product?.id}
                ctaUrl={`${location.origin}${
                  post.feed?.product
                    ? generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
                        feedId: post.feed.id,
                        productSlug: post.feed?.product.slug,
                      })
                    : generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
                        feedId: post.feed.id,
                      })
                }?postId=${post.id}`}
              />
            </Grid>
            <Grid item className={classes.buttonsContainer}>
              {getButtons()}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )

  function getButtons() {
    return [
      <DiscoButton
        key={"no-thanks"}
        testid={`${testid}.button.no-thanks`}
        color={"grey"}
        variant={"outlined"}
        onClick={() => props.onClose?.()}
      >
        {"No Thanks"}
      </DiscoButton>,
      <Form.SubmitButton
        key={"submit"}
        id={"SharePostForm"}
        testid={`${testid}.button.confirm`}
        type={"submit"}
        form={form}
        shouldDisplaySpinner={form.isSubmitting}
        disabled={form.isSubmitting}
      >
        {"Share By Email"}
      </Form.SubmitButton>,
    ]
  }

  async function handleSubmit() {
    const { didSave } = await form.submit({
      postId: form.state.postId,
      emailSubject: form.state.content.notificationEmailSubject || "",
      notificationEmailCtaButton:
        form.state.content.notificationEmailCtaButton || "enabled",
    })
    if (!didSave) return

    // close modal
    props.onClose?.()

    displaySuccessToast({ message: "Post shared via email!" })
  }
}
const useStyles = makeUseStyles((theme) => ({
  buttonsOverride: {
    marginLeft: "unset",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

/** looks for `openSharePostModal` in location state and renders the modal with the given postId from state value */
export function LocationStateControlledSharePostModal() {
  const location = useLocation<LocationState>()
  const history = useHistory()
  if (!location.state?.openSharePostModal) return null
  return (
    <SharePostModal
      postId={location.state.openSharePostModal}
      onClose={() =>
        history.replace({
          ...location,
          state: { ...location.state, openSharePostModal: undefined },
        })
      }
    />
  )
}

export default observer(SharePostModal)
