import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ScheduledPostsDrawerContent from "@/post/list/ScheduledPostsDrawerContent"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"

export default function ScheduledPostsDrawer() {
  const drawer = useGlobalDrawer("scheduledPosts")
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"large"}
      onClose={drawer.close}
      containerClasses={{ drawerContainer: classes.drawer }}
    >
      <DiscoDrawerHeader
        title={"Scheduled Posts"}
        testid={"ScheduledPostsDrawerHeader"}
        onClose={drawer.close}
        className={classes.drawerHeader}
      />
      {drawer.isOpen && <ScheduledPostsDrawerContent />}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawer: {
    padding: 0,
  },
  drawerHeader: {
    padding: theme.spacing(3, 3, 0),
  },
}))
