import { isToday, isYesterday } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { DATE_FORMAT, DAYS_IN_A_WEEK } from "../../../../utils/time/timeConstants"
import {
  formatDateWithOptions,
  getDifferenceInDays,
} from "../../../../utils/time/timeUtils"

function generateDateText(
  timeZone: string,
  recentActivityDatetime: string | null
): string {
  if (!recentActivityDatetime) return ""

  const recentActivityDateTime = new Date(recentActivityDatetime)
  const zonedDate = utcToZonedTime(recentActivityDateTime, timeZone)

  if (isToday(zonedDate)) {
    return "Today"
  }

  if (isYesterday(zonedDate)) {
    return "Yesterday"
  }

  const daysDifference = Math.abs(getDifferenceInDays(timeZone, recentActivityDateTime))

  // If the difference is 1 day, it means that the activity happened 2 days ago (yesterday and today are already handled according to timezone)
  if (daysDifference === 1) {
    return "2 days ago"
  }

  if (daysDifference < DAYS_IN_A_WEEK) {
    return `${daysDifference} days ago`
  }

  const text = formatDateWithOptions({
    timeZone,
    format: DATE_FORMAT.DEFAULT_WITHOUT_COMMA,
  })(recentActivityDateTime)

  return text
}

export { generateDateText }
