import { PostFormState } from "@/content/form/util/contentFormUtil"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  EditPostInput,
  EditPostModalMutation,
} from "@/post/edit/__generated__/EditPostModalMutation.graphql"
import PostFormFields from "@/post/PostFormFields"
import { DiscoEditorMentionsFragment$key } from "@components/editor/__generated__/DiscoEditorMentionsFragment.graphql"
import Form from "@components/form/Form"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import { MutableRefObject, useRef, useState } from "react"
import { generatePath, useHistory } from "react-router-dom"

interface Props {
  form: FormStore<EditPostInput & PostFormState, EditPostModalMutation>
  onClose?: (ignoreUnsavedChanges: boolean) => void
  onEdit?: VoidFunction
  contentKey?: DiscoEditorMentionsFragment$key
  editorButtonsRef?: MutableRefObject<HTMLDivElement | null>
}

function EditPostForm(props: Props) {
  const { form, onClose, onEdit, contentKey, editorButtonsRef } = props
  const activeProduct = useActiveProduct()
  const history = useHistory()
  const isSubmitConfirmed = useRef(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const scheduledPostsDrawer = useGlobalDrawer("scheduledPosts")

  if (isConfirmationModalOpen)
    return (
      <DiscoWarningModal
        isOpen
        onClose={() => onClose?.(false)}
        title={"Confirm Scheduling"}
        modalContentLabel={"Confirm Scheduling"}
        testid={"CreatePostModal.confirm-scheduled-share"}
        variant={"primary"}
        description={`This Experience is currently in draft mode. If the Experience is still in draft mode at the time this post is published, the post will not be shared by email.`}
        cancelButtonText={"Cancel"}
        customConfirmationButton={
          <DiscoButton
            width={"100%"}
            onClick={() => {
              isSubmitConfirmed.current = true
              handleSubmit()
            }}
            shouldDisplaySpinner={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            {"Schedule Post"}
          </DiscoButton>
        }
      />
    )

  return (
    <Form id={"EditPostForm"} testid={"EditPostForm"} onSubmit={handleSubmit}>
      <PostFormFields
        contentKey={contentKey}
        form={form}
        editorButtonsRef={editorButtonsRef}
      />
    </Form>
  )

  async function handleSubmit() {
    // when publishing a post in a product-level experience
    // show a confirmation modal so admin is aware the email will not be sent
    // if the product is still in draft at time of publishing
    if (
      form.state.selectedFeed.product?.id &&
      form.state.selectedFeed.product.status !== "published" &&
      form.state.isScheduled &&
      form.state.content.sendNotificationEmail &&
      !isSubmitConfirmed.current
    )
      return setIsConfirmationModalOpen(true)

    const { isScheduled } = form.state

    const { didSave, response } = await form.submit({
      postId: form.state.postId,
      content: {
        ...(form.changedState?.content || {}),
        richEditorNotificationEmailBodyContent: form.state.selectedFeed.product
          ? // ensure email will go out with post body if the post/feed is product-level
            form.state?.content?.richEditorDescriptionContent
          : form.state?.content?.richEditorNotificationEmailBodyContent,
        releaseDatetime: form.state.isScheduled
          ? form.state.content?.releaseDatetime
          : null,
      },
    })
    if (!didSave) return

    // close modal
    if (onEdit) {
      onEdit()
    }
    if (onClose) {
      onClose(true)
    }

    if (!response?.node || !activeProduct) return
    if (scheduledPostsDrawer.isOpen && !isScheduled) {
      // If the scheduled posts drawer is open and you edited a post to
      // be released immediately, close the drawer and redirect to the released posts feed
      scheduledPostsDrawer.close()

      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug: activeProduct.slug,
          feedId: response.node.feedId,
        })
      )
    }
  }
}

export default observer(EditPostForm)
