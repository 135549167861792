import { DATE_FORMAT } from "@utils/time/timeConstants"
import {
  formatDateWithOptions,
  getDifferenceInDaysBetweenDates,
  isDateTimeInTheFuture,
} from "@utils/time/timeUtils"

export namespace OrganizationUtils {
  export function daysUntilTrialExpired(trialEndDate: string | null | undefined) {
    return trialEndDate && isDateTimeInTheFuture(trialEndDate)
      ? getDifferenceInDaysBetweenDates(
          formatDateWithOptions({
            format: DATE_FORMAT.API_FORMAT,
            shouldShiftDateToCompensateForTimezone: false,
          })(new Date(trialEndDate)),
          formatDateWithOptions({
            format: DATE_FORMAT.API_FORMAT,
            shouldShiftDateToCompensateForTimezone: false,
          })(new Date())
        )
      : 0
  }
}
