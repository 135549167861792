import { AiApiGeneratePostTemplate } from "@/common/AiApi"
import CreatePostButton from "@/post/add/CreatePostButton"
import AIButton from "@components/ai/AIButton"
import { OverridableDiscoButtonChildren } from "@disco-ui/button/OverridableDiscoButton"
import useHasEntitlement from "@utils/hook/useHasEntitlement"

export interface CreatePostWithAIButtonProps {
  feedId: string
  children?: OverridableDiscoButtonChildren
  aiTemplate?: AiApiGeneratePostTemplate
}

function CreatePostWithAIButton({
  feedId,
  children,
  aiTemplate = "default",
}: CreatePostWithAIButtonProps) {
  const hasEntitlement = useHasEntitlement("ai_inline_prompts")

  if (!hasEntitlement) return null

  return (
    <CreatePostButton
      testid={"CreatePostWithAIButton"}
      feedId={feedId}
      checkQueryParamForModalOpen={false}
      aiTemplate={aiTemplate}
    >
      {children ||
        ((buttonProps) => <AIButton {...buttonProps}>{"Create Post with AI"}</AIButton>)}
    </CreatePostButton>
  )
}

export default CreatePostWithAIButton
