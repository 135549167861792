/**
 * @generated SignedSource<<b84d5fcf02e4a74f9cd2ed3118c2853b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type SharePostModalQuery$variables = {
  id: string;
};
export type SharePostModalQuery$data = {
  readonly node: {
    readonly id: string;
    readonly __typename: "Post";
    readonly feed: {
      readonly id: string;
      readonly name: string;
      readonly product: {
        readonly id: string;
        readonly name: string;
        readonly status: ProductStatus;
        readonly type: ProductType;
        readonly startDate: string | null;
        readonly waitingRoomEndsAt: string | null;
        readonly slug: string;
      } | null;
    };
    readonly content: {
      readonly id: string;
      readonly name: string | null;
      readonly sendNotificationEmail: boolean | null;
      readonly notificationEmailSubject: string | null;
      readonly richEditorDescriptionContent: string | null;
      readonly richEditorNotificationEmailBodyContent: string | null;
      readonly notificationEmailCtaButton: ContentNotificationEmailCtaButton;
      readonly createdBy: {
        readonly id: string;
      } | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment" | "PostPreviewEmailFormFieldsFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type SharePostModalQuery = {
  variables: SharePostModalQuery$variables;
  response: SharePostModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Feed",
  "kind": "LinkedField",
  "name": "feed",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "waitingRoomEndsAt",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendNotificationEmail",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationEmailSubject",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorDescriptionContent",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorNotificationEmailBodyContent",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationEmailCtaButton",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SharePostModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePermissionsFragment"
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PostPreviewEmailFormFieldsFragment"
              }
            ],
            "type": "Post",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SharePostModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notificationEmailSentDatetime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v13/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "Post",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6a4acbb3a04c3c0ba5840999a8cffa7",
    "id": null,
    "metadata": {},
    "name": "SharePostModalQuery",
    "operationKind": "query",
    "text": "query SharePostModalQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Post {\n      id\n      __typename\n      ...usePermissionsFragment\n      feed {\n        id\n        name\n        product {\n          id\n          name\n          status\n          type\n          startDate\n          waitingRoomEndsAt\n          slug\n        }\n      }\n      content {\n        id\n        name\n        sendNotificationEmail\n        notificationEmailSubject\n        richEditorDescriptionContent\n        richEditorNotificationEmailBodyContent\n        notificationEmailCtaButton\n        createdBy {\n          id\n        }\n      }\n      ...PostPreviewEmailFormFieldsFragment\n    }\n    id\n  }\n}\n\nfragment PostPreviewEmailFormFieldsFragment on Post {\n  id\n  feedId\n  product {\n    id\n    name\n    slug\n  }\n  content {\n    id\n    notificationEmailSentDatetime\n  }\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "3934eda0b8dd5c5b2077a0f068d4fa4d";

export default node;
