import {
  ContentFormMoveStepOptions,
  ContentFormState,
  ContentFormStore,
} from "@/content/form/util/contentFormUtil"
import ContentFormUtil from "../../content/form/util/contentFormUtil"
export namespace PostUtil {
  export function getPostFormModalTitle(
    mode: "add" | "edit",
    step: ContentFormState["step"]
  ) {
    if (step === "default") {
      return `${mode === "add" ? "Create" : "Edit"} Post`
    } else if (step === "schedule") {
      return `Schedule Post`
    }
    return `Post`
  }

  export function moveToScheduleStep(
    form: ContentFormStore,
    { formValidator }: ContentFormMoveStepOptions | undefined = {}
  ) {
    ContentFormUtil.moveToScheduleStep(form, {
      formValidator,
      updateCallback: () => {
        // scheduled posts for experience feeds should use content for email preview
        form.state.content.richEditorNotificationEmailBodyContent =
          form.state.content.richEditorDescriptionContent
        // use the post title for the subject, if one exists
        form.state.content.notificationEmailSubject = form.state.content.name
          ? `🔔 New Post - ${form.state.content.name}`
          : "🔔 New Post"
        form.state.content.sendNotificationEmail = true
      },
    })
  }
  export function moveToDefaultStep(
    form: ContentFormStore,
    { formValidator }: ContentFormMoveStepOptions | undefined = {}
  ) {
    ContentFormUtil.moveToDefaultStep(form, {
      formValidator,
      updateCallback: () => {
        form.state.content.sendNotificationEmail = false
      },
    })
  }
}
