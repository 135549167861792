/**
 * @generated SignedSource<<55c3ad2434f5603c9f33102578449fc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserMembershipEntity = "occurrence" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganizationGuestListRow_Member$data = {
  readonly id: string;
  readonly email: string | null;
  readonly createdAt: string;
  readonly hasBeenInvited: boolean | null;
  readonly userMemberships: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly entity: UserMembershipEntity;
        readonly occurrence: {
          readonly name: string | null;
        } | null;
        readonly user: {
          readonly fullName: string;
        };
      };
    }>;
  };
  readonly user: {
    readonly id: string;
    readonly bio: string;
    readonly fullName: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGuestListActionDropdownFragment">;
  readonly " $fragmentType": "OrganizationGuestListRow_Member";
};
export type OrganizationGuestListRow_Member$key = {
  readonly " $data"?: OrganizationGuestListRow_Member$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGuestListRow_Member">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationGuestListRow_Member",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenInvited",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationGuestListActionDropdownFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "userMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Occurrence",
                  "kind": "LinkedField",
                  "name": "occurrence",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GuestMembership",
  "abstractKey": null
};
})();

(node as any).hash = "634fd40e2a34638e6453a4dd8ae48253";

export default node;
