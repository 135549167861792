interface FacebookPixelPurchaseProps {
  value: number
  currency: string
}

class FacebookPixel {
  static pageView() {
    this._callWith("track", "PageView")
  }

  static grantConsent() {
    this._callWith("consent", "grant")
  }

  static revokeConsent() {
    this._callWith("consent", "revoke")
  }

  static trackPurchase(props: FacebookPixelPurchaseProps) {
    const { value, currency } = props
    this._callWith("track", "Purchase", {
      value,
      currency: currency.toUpperCase(),
    })
  }

  static isLoaded() {
    return typeof window !== "undefined" && "fbq" in window
  }

  /** Call the fbq object with the given arguments
   *  The call is ignored if fbq is not initialized */
  private static _callWith = (...args: any[]) => {
    if (this.isLoaded()) {
      fbq(...(args as Parameters<facebook.Pixel.Event>))
    }
  }
}

export default FacebookPixel
