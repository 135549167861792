import React from "react"
import { Helmet } from "react-helmet-async"

type FacebookPixelConfig = {
  facebookPixelId: string
}

const FacebookPixelScript: React.FC<FacebookPixelConfig> = ({ facebookPixelId }) => {
  // Note: The page view tracking is removed from the snippet
  // Manually track page view using usePageLoad instead

  return (
    <>
      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('consent', 'revoke');
                fbq('init', '${facebookPixelId}');
            `}
        </script>
      </Helmet>
      <noscript>
        {/* eslint-disable jsx-a11y/alt-text */}
        <img
          height={1}
          width={1}
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  )
}

export default FacebookPixelScript
