interface GoogleAnalyticsPurchaseProps {
  value: number
  currency: string
  orderId: string
  discountCode?: string
}

class GoogleAnalytics {
  static pageView(pathname: string) {
    this._callWith("event", "page_view", {
      page_path: pathname,
    })
  }

  static grantConsent() {
    this._callWith("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    })
  }

  static revokeConsent() {
    this._callWith("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    })
  }

  static trackPurchase(props: GoogleAnalyticsPurchaseProps) {
    const { value, currency, orderId, discountCode } = props
    this._callWith("event", "purchase", {
      transaction_id: orderId,
      affiliation: "Disco",
      value,
      currency,
      discountCode,
    })
  }

  static isLoaded() {
    return typeof window !== "undefined" && "gtag" in window
  }

  /** Call the gtag object with the given arguments
   *  The call is ignored if gtag is not initialized */
  private static _callWith = (...args: any[]) => {
    if (this.isLoaded()) {
      gtag(...(args as Parameters<Gtag.Gtag>))
    }
  }
}

export default GoogleAnalytics
