import { UnpinPostButtonMutation } from "@/post/pin/__generated__/UnpinPostButtonMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButtonSkeleton, DiscoIcon } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { graphql, useMutation } from "react-relay"

interface UnpinPostButtonProps {
  refetch: VoidFunction
  pinnedPostId: GlobalID
  children?: OverridableDiscoButtonChildren
}

function UnpinPostButton({ refetch, pinnedPostId, children }: UnpinPostButtonProps) {
  const [commit, isUnpinning] = useMutation<UnpinPostButtonMutation>(graphql`
    mutation UnpinPostButtonMutation($input: UnpinPostInput!) {
      unpinPost(input: $input) @deleteRecord
    }
  `)

  return (
    <OverridableDiscoButton
      leftIcon={<DiscoIcon icon={"pin"} />}
      data-testid={"UnpinPostButton"}
      onClick={handleUnpinPost}
      shouldDisplaySpinner={isUnpinning}
      disabled={isUnpinning}
      stopPropagation
    >
      {children || "Unpin post"}
    </OverridableDiscoButton>
  )

  function handleUnpinPost() {
    commit({
      variables: { input: { pinnedPostId } },
      onCompleted() {
        refetch()
        displaySuccessToast({ message: "Post unpinned!" })
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}

export function UnpinPostButtonSkeleton() {
  return <DiscoButtonSkeleton />
}

export default Relay.withSkeleton({
  component: UnpinPostButton,
  skeleton: UnpinPostButtonSkeleton,
})
