/**
 * @generated SignedSource<<ad10e858d25145332bf4d553e10943d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnpinPostInput = {
  pinnedPostId: string;
};
export type UnpinPostButtonMutation$variables = {
  input: UnpinPostInput;
};
export type UnpinPostButtonMutation$data = {
  readonly unpinPost: string;
};
export type UnpinPostButtonMutation = {
  variables: UnpinPostButtonMutation$variables;
  response: UnpinPostButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "unpinPost",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnpinPostButtonMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnpinPostButtonMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "deleteRecord",
        "key": "",
        "kind": "ScalarHandle",
        "name": "unpinPost"
      }
    ]
  },
  "params": {
    "cacheID": "30085e6a3d0badc3f3a4cdac14a162e0",
    "id": null,
    "metadata": {},
    "name": "UnpinPostButtonMutation",
    "operationKind": "mutation",
    "text": "mutation UnpinPostButtonMutation(\n  $input: UnpinPostInput!\n) {\n  unpinPost(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "7afbb2dde818129a936d99bab94025b0";

export default node;
