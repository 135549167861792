import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { RemoveCommunityGuestButtonMutation } from "@/organization/people/actions/remove/__generated__/RemoveCommunityGuestButtonMutation.graphql"
import CommunityGuestCheckList from "@/organization/people/member-checklist/CommunityGuestCheckList"
import { GuestCheckListFormState } from "@/organization/people/member-checklist/CommunityGuestCheckListTitle"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal, DiscoText, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

interface RemoveCommunityGuestButtonProps extends TestIDProps {
  children: OverridableDiscoButtonChildren
  memberships: GuestCheckList[]
  onRemove?: () => void
}

function RemoveCommunityGuestButton({
  children,
  memberships,
  onRemove,
  testid = "RemoveCommunityGuestButton",
}: RemoveCommunityGuestButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()

  const form = useFormStore<RemoveCommunityGuestButtonMutation, GuestCheckListFormState>(
    graphql`
      mutation RemoveCommunityGuestButtonMutation($input: DeleteGuestMembershipsInput!) {
        response: deleteGuestMemberships(input: $input) {
          deletedGuestMembershipIds @deleteRecord
          organization {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      memberships,
    }
  )
  const totalGuestsToRemove = form.state.memberships.length

  useEffect(() => {
    if (isModalOpen) {
      form.state.memberships.replace(memberships)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={openModal}>
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        testid={`${testid}.warning-modal`}
        isOpen={isModalOpen}
        modalContentLabel={`Remove Guests`}
        onClose={closeModal}
        title={`Remove Guests`}
        maxHeight={"90vh"}
        body={
          <div className={classes.modalBody}>
            <DiscoText testid={`${testid}.description`}>
              <DiscoText component={"span"} color={"text.danger"} variant={"body-md-700"}>
                {`WARNING: `}
              </DiscoText>
              {`All data related to the following Guest will be removed, along with their access to the community. This is a permanent action and cannot be undone.`}
            </DiscoText>
            <CommunityGuestCheckList form={form} memberships={memberships} />
          </div>
        }
        buttons={[
          <DiscoButton
            key={"cancel"}
            color={"grey"}
            variant={"outlined"}
            onClick={closeModal}
          >
            {"Cancel"}
          </DiscoButton>,
          <DiscoTooltip
            key={"remove-guests"}
            content={
              "Selected guests will have all access removed, but will be able to re-register for guest events"
            }
          >
            <DiscoButton
              onClick={handleRemoveGuestMemberships}
              testid={`${testid}.confirm`}
              shouldDisplaySpinner={form.isSubmitting}
              disabled={form.isSubmitting}
            >
              {`Remove ${totalGuestsToRemove} ${
                totalGuestsToRemove === 1 ? "Guest" : "Guests"
              }`}
            </DiscoButton>
          </DiscoTooltip>,
        ]}
      />
    </>
  )

  async function handleRemoveGuestMemberships() {
    const { didSave } = await form.submit(
      {
        organizationId: activeOrganization.id,
        guestMembershipIds: form.state.memberships.map((m) => m.guestMembershipId),
      },
      {
        updater: (store) => {
          const organizationRecord = store.get(activeOrganization.id)
          if (!organizationRecord) return
          ConnectionHandler.getConnections(
            organizationRecord,
            "CommunityGuestReportTable__guestMemberships"
          ).forEach((connection) => connection.invalidateRecord())
        },
      }
    )
    if (!didSave) return

    closeModal()
    displayToast({
      message: `${totalGuestsToRemove} ${
        totalGuestsToRemove === 1 ? "Guest" : "Guests"
      } removed from the community`,
      testid: `${testid}.success-toast`,
    })
    onRemove?.()
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalBody: {
    display: "grid",
    gap: theme.spacing(3),
  },
}))

export default observer(RemoveCommunityGuestButton)
