import MessageCommunityGuestsDrawer from "@/organization/people/actions/message/MessageCommunityGuestsDrawer"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface MessageCommunityGuestsButtonProps extends TestIDProps {
  children: OverridableDiscoButtonChildren
  guestMemberships: GuestCheckList[]
  onMessage?: () => void
  zIndex?: React.CSSProperties["zIndex"]
}

function MessageCommunityGuestsButton({
  children,
  guestMemberships,
  onMessage,
  testid = "MessageCommunityGuestsButton",
  zIndex,
}: MessageCommunityGuestsButtonProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={onOpen}>
        {children}
      </OverridableDiscoButton>
      <MessageCommunityGuestsDrawer
        testid={testid}
        isOpen={isOpen}
        onClose={onClose}
        onMessage={onMessage}
        memberships={guestMemberships}
        zIndex={zIndex}
      />
    </>
  )
}

function MessageCommunityGuestsButtonSkeleton() {
  return <DiscoButtonSkeleton />
}

export default Relay.withSkeleton<MessageCommunityGuestsButtonProps>({
  component: observer(MessageCommunityGuestsButton),
  skeleton: MessageCommunityGuestsButtonSkeleton,
})
