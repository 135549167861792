import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GuestCheckList } from "@/organization/people/page/guest/CommunityGuestReportTable"
import {
  CommunityGuestSelectAllCheckboxQuery,
  CommunityGuestSelectAllCheckboxQuery$variables,
} from "@/organization/people/page/guest/__generated__/CommunityGuestSelectAllCheckboxQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCheckbox, DiscoSpinner } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface CommunityGuestSelectAllCheckboxProps extends TestIDProps {
  className?: string
  isAllSelected: boolean
  setSelectedMembershipIds: (memberships: GuestCheckList[]) => void
  guestMembershipArgs: Omit<CommunityGuestSelectAllCheckboxQuery$variables, "id">
}

function CommunityGuestSelectAllCheckbox({
  testid,
  className,
  isAllSelected,
  setSelectedMembershipIds,
  guestMembershipArgs,
}: CommunityGuestSelectAllCheckboxProps) {
  const activeOrganization = useActiveOrganization()!

  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()

  if (isLoading)
    return (
      <div className={classes.spinner}>
        <DiscoSpinner size={"sm"} />
      </div>
    )

  return (
    <DiscoCheckbox
      testid={`${testid}.select-all`}
      label={null}
      className={className}
      onChange={handleToggleSelectAll}
      checked={isAllSelected}
    />
  )

  async function handleToggleSelectAll() {
    if (isAllSelected) {
      setSelectedMembershipIds([])
      return
    }

    setIsLoading(true)

    const res = await Relay.runQuery<CommunityGuestSelectAllCheckboxQuery>(
      query,
      {
        id: activeOrganization.id,
        ...guestMembershipArgs,
      },
      {
        fetchPolicy: "store-or-network",
      }
    )

    const guestMemberships = Relay.connectionToArray(res?.organization?.guestMemberships)
    setSelectedMembershipIds(
      guestMemberships.map((gm) => ({
        guestMembershipId: gm.id,
        userId: gm.user.id,
        email: gm.email || "",
      }))
    )

    setIsLoading(false)
  }
}

const query = graphql`
  query CommunityGuestSelectAllCheckboxQuery(
    $id: ID!
    $search: String
    $hasBeenInvited: Boolean
  ) {
    organization: node(id: $id) {
      ... on Organization {
        guestMemberships(search: $search, hasBeenInvited: $hasBeenInvited) {
          edges {
            node {
              id
              email
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`

const useStyles = makeUseStyles({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "42px",
    width: "42px",
  },
})

export default CommunityGuestSelectAllCheckbox
