import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import EditPostModal from "@/post/edit/EditPostModal"
import { EditPostButtonFragment$key } from "@/post/edit/__generated__/EditPostButtonFragment.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonModalProps,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import usePermissions from "@utils/hook/usePermissions"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type EditPostParams = {
  editPost?: "true"
}

interface EditPostButtonProps extends OverridableDiscoButtonProps {
  postKey: EditPostButtonFragment$key
  onEdit?: VoidFunction
}

function EditPostButton(props: EditPostButtonProps) {
  const { postKey, onEdit, children, ...rest } = props
  const [_, setParams] = useQueryParamState<EditPostParams>()

  const post = useFragment<EditPostButtonFragment$key>(
    graphql`
      fragment EditPostButtonFragment on Post {
        id
        ...usePermissionsFragment
      }
    `,
    postKey
  )

  const stopPropagation = (event: { stopPropagation: () => void }) => {
    // prevent propagation to parent dashboard block to allow dragging editor blocks
    event.stopPropagation()
  }
  const ModalComponent = useCallback(
    ({ isOpen, onClose }: OverridableDiscoButtonModalProps) => {
      // Reset form state on each open
      if (!isOpen) return null
      return (
        <div onDragStart={stopPropagation}>
          <EditPostModal id={post.id} onEdit={onEdit} onClose={handleClose} />
        </div>
      )

      function handleClose() {
        onClose()
        setParams({ editPost: undefined })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [post.id, onEdit]
  )

  // Owners/admins/managers/instructors can always see "Edit" button but get a warning
  // modal to explain they can only edit their own posts.
  const permissions = usePermissions(post)
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const isAdmin =
    activeProduct?.viewerIsManagerOrInstructor || activeOrganization?.viewerIsOwnerOrAdmin
  if (!permissions.has("post.edit") && !isAdmin) return null

  return (
    <OverridableDiscoButton
      leftIcon={"add"}
      testid={"EditPostButton"}
      modal={ModalComponent}
      onClick={() => setParams({ editPost: "true" })}
      {...rest}
    >
      {children || "Edit Post"}
    </OverridableDiscoButton>
  )
}

export default EditPostButton
