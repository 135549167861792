import { useLabel } from "@/core/context/LabelsContext"
import RadioIcon from "@/core/ui/images/empty-state/radio.svg"
import { DiscoEmptyState } from "@disco-ui"

type Props = {
  canCreatePosts: boolean
}

function OrganizationFeedPostsEmptyState({ canCreatePosts }: Props) {
  const experienceLabel = useLabel("experience")
  return (
    <DiscoEmptyState
      testid={"OrganizationFeedPostsPage.empty-state"}
      icon={<RadioIcon width={139} height={152} />}
      title={canCreatePosts ? "You have no Posts" : "There are no Posts yet"}
      subtitle={
        canCreatePosts
          ? `Posts are a way of communicating with your entire Community and drive them back to the system to create engagement. For example, you can remind them of an upcoming ${experienceLabel.singular}.`
          : "This feed is currently empty. Please check back later!"
      }
    />
  )
}

export default OrganizationFeedPostsEmptyState
