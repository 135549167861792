import AutomationSlackSendAsFields from "@/admin/automation/form/actions/AutomationSlackSendAsFields"
import { useGetDefaultSlackMessageSendAs } from "@/admin/automation/utils/automationUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import MessageCommunityMembersEmailFormFields from "@/organization/people/actions/message/MessageCommunityMembersEmailFormFields"
import MessageCommunityMembersSendMethodDropdown from "@/organization/people/actions/message/MessageCommunityMembersSendMethodDropdown"
import {
  MessageCommunityMembersDrawerContentMutation,
  SendMessageInput,
} from "@/organization/people/actions/message/__generated__/MessageCommunityMembersDrawerContentMutation.graphql"
import { MessageCommunityMembersDrawerContentPreview_EmailMutation } from "@/organization/people/actions/message/__generated__/MessageCommunityMembersDrawerContentPreview_EmailMutation.graphql"
import { MessageCommunityMembersDrawerContentPreview_SlackMessageMutation } from "@/organization/people/actions/message/__generated__/MessageCommunityMembersDrawerContentPreview_SlackMessageMutation.graphql"
import { MessageCommunityMembersDrawerContentQuery } from "@/organization/people/actions/message/__generated__/MessageCommunityMembersDrawerContentQuery.graphql"
import CommunityMembersCheckList, {
  CommunityMembersCheckListSkeleton,
} from "@/organization/people/member-checklist/CommunityMembersCheckList"
import { OrganizationMembershipIdMemberChecklist } from "@/organization/people/member-checklist/CommunityMembersCheckListTitle"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { EditorInstance } from "@components/editor/LexicalEditor"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoAlert,
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoMacrosTooltip from "@disco-ui/tooltip/DiscoMacrosTooltip"
import { range } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { removeSearchParams, useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type SendMessageMethod = "email" | "slack-direct-message"
export type SendMessageToMembers =
  | OrganizationMembershipIdMemberChecklist[]
  | "community"
  | "product"
type MessageCommunityMembersFormState = Omit<
  SendMessageInput,
  "organizationMembershipIds"
> & {
  memberships: OrganizationMembershipIdMemberChecklist[]
  sendMessageMethod?: SendMessageMethod | null
}
export type MessageCommunityMembersFormStore = FormStore<
  MessageCommunityMembersFormState,
  MessageCommunityMembersDrawerContentMutation
>

export type AIEmailTemplate =
  | "message-inactive-members"
  | "message-members-not-started-curriculum"
  | "message-engaged-members"
export type MessageCommunityMembersDrawerQueryParams = {
  aet: AIEmailTemplate
}

interface Props extends TestIDProps {
  onClose: () => void
  onMessage?: () => void
  sendTo: SendMessageToMembers
}

function MessageCommunityMembersDrawerContent({
  testid,
  onClose,
  onMessage,
  sendTo,
}: Props) {
  const classes = useStyles()
  const memberLabel = useLabel("admin_member")
  const { authUser } = useAuthUser({ required: true })
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const params = useQueryParams<MessageCommunityMembersDrawerQueryParams>()
  const { aiInlinePrompts } = useFeatureFlags()
  const [editor, setEditor] = useState<EditorInstance | null>(null)
  const history = useHistory()

  const isSendingToMemberships = Array.isArray(sendTo)
  const { organization } = useLazyLoadQuery<MessageCommunityMembersDrawerContentQuery>(
    graphql`
      query MessageCommunityMembersDrawerContentQuery(
        $id: ID!
        $organizationMembershipIds: [ID!]!
        $skipMemberships: Boolean!
      ) {
        organization: node(id: $id) {
          ... on Organization {
            viewerMembership {
              slackUserId
            }
            organizationMemberships(
              organizationMembershipIds: $organizationMembershipIds
            ) @skip(if: $skipMemberships) {
              edges {
                node {
                  id
                  slackUserId
                }
              }
            }
            ...MessageCommunityMembersSendMethodDropdownFragment
            ...automationUtils_useGetDefaultSlackMessageSendAsFragment
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      organizationMembershipIds: isSendingToMemberships
        ? sendTo.map((m) => m.organizationMembershipId)
        : [],
      skipMemberships: !isSendingToMemberships,
    }
  )

  const previewEmailForm =
    useFormStore<MessageCommunityMembersDrawerContentPreview_EmailMutation>(
      graphql`
        mutation MessageCommunityMembersDrawerContentPreview_EmailMutation(
          $input: PreviewSendMessageEmailInput!
        ) {
          response: previewSendMessageEmail(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `,
      {
        organizationId: activeOrganization.id,
        subject: "",
        richEditorBody: "",
      }
    )

  const previewSlackMessageForm =
    useFormStore<MessageCommunityMembersDrawerContentPreview_SlackMessageMutation>(
      graphql`
        mutation MessageCommunityMembersDrawerContentPreview_SlackMessageMutation(
          $input: PreviewSendSlackMessageInput!
        ) {
          response: previewSendSlackMessage(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `,
      {
        organizationId: activeOrganization.id,
        richEditorBody: "",
      }
    )

  const defaultSlackMessageSendAs = useGetDefaultSlackMessageSendAs(organization)

  const form = useFormStore<
    MessageCommunityMembersDrawerContentMutation,
    MessageCommunityMembersFormState
  >(
    graphql`
      mutation MessageCommunityMembersDrawerContentMutation($input: SendMessageInput!) {
        response: sendMessage(input: $input) {
          automation: node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      memberships: isSendingToMemberships ? sendTo : [],
      sendTo: isSendingToMemberships ? null : sendTo,
      emailMessageInput: {
        subject: "",
        richEditorBody: JSON.stringify(EditorUtils.createParagraphs([""])),
      },
      slackDirectMessageInput: {
        richEditorBody: JSON.stringify(EditorUtils.createParagraphs([""])),
        sendAs: defaultSlackMessageSendAs,
      },
      sendMessageMethod: "email",
    }
  )

  useEffect(() => {
    if (!activeProduct || !aiInlinePrompts) return
    const AI_PRODUCT_EMAIL_TEMPLATES: Record<
      AIEmailTemplate,
      { subject: string; prompt: string }
    > = {
      "message-inactive-members": {
        subject: `Checking in on your progress in ${activeProduct.name}`,
        prompt: `Generate a message that will re-engage an inactive member in the ${activeProduct.name} course.
        The tone should be understanding, and encourage the learner to take manageable steps back into the course.
        Sign the email as ${authUser.firstName}.`,
      },
      "message-members-not-started-curriculum": {
        subject: `Let's get started!`,
        prompt: `Generate a message that will motivate members to start on the ${activeProduct.name} curriculum.
        The tone should be supportive and non-judgemental, offering assistance and highlighting the ease of getting started on the first module.
        Sign the email as ${authUser.firstName}.`,
      },
      "message-engaged-members": {
        subject: `Keep it up!`,
        prompt: `Generate a message that will encourage the most engaged members in the ${activeProduct.name} course.
        The tone should be congratulatory and motivating, encouraging the learner to continue their progress.
        Sign the email as ${authUser.firstName}.`,
      },
    }
    async function handleAIEmailTemplate() {
      // get template from params
      const template: AIEmailTemplate = params.aet
      if (!editor || !template) return
      // clear params
      history.replace({
        search: removeSearchParams(history.location.search, ["aet"]),
      })
      // set the subject line
      form.state.emailMessageInput!.subject = AI_PRODUCT_EMAIL_TEMPLATES[template].subject
      // generate text in editor
      await EditorUtils.aiStreamToEditor(editor, {
        organizationId: activeOrganization.id,
        productId: activeProduct?.id,
        responseType: "email",
        prompt: AI_PRODUCT_EMAIL_TEMPLATES[template].prompt,
      })
    }
    handleAIEmailTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.aet, editor, aiInlinePrompts])

  if (!organization) return null

  const totalMembersNotConnectedToSlack = Relay.connectionToArray(
    organization?.organizationMemberships
  ).filter(
    (om) =>
      !om.slackUserId &&
      form.state.memberships.some((m) => m.organizationMembershipId === om.id)
  ).length
  const isUserConnectedToSlack = Boolean(organization.viewerMembership?.slackUserId)

  return (
    <Form
      id={"MessageCommunityMembersForm"}
      testid={"MessageCommunityMembersForm"}
      onSubmit={handleSendMessage}
      classes={{ formFieldsContainer: classes.form, buttonsRoot: classes.buttons }}
      hasStickyButtons
      height={"100%"}
      buttons={
        form.state.sendMessageMethod && (
          <>
            {form.state.sendMessageMethod === "email" && (
              <DiscoMacrosTooltip
                usePlace={"your messages"}
                macros={["user.firstName", "user.lastName"]}
              />
            )}
            {form.state.sendMessageMethod === "slack-direct-message" &&
            !isUserConnectedToSlack ? (
              <DiscoTooltip
                content={
                  "Can not send preview to yourself because you are not connected to the community's workspace"
                }
              >
                <span>
                  <DiscoButton color={"transparent"} leftIcon={"send"} disabled>
                    {"Send Me Preview"}
                  </DiscoButton>
                </span>
              </DiscoTooltip>
            ) : (
              <DiscoButton
                onClick={handlePreviewButtonClick}
                color={"transparent"}
                leftIcon={"send"}
                testid={"MessageCommunityMembersForm.preview"}
              >
                {"Send Me Preview"}
              </DiscoButton>
            )}
            <DiscoButton
              onClick={handleSendMessage}
              testid={`${testid}.confirm`}
              shouldDisplaySpinner={form.isSubmitting}
              className={classes.sendButton}
            >
              {"Send Message"}
            </DiscoButton>
          </>
        )
      }
    >
      {form.state.sendMessageMethod === "slack-direct-message" &&
        totalMembersNotConnectedToSlack > 0 && (
          <div>
            <DiscoAlert
              message={`${totalMembersNotConnectedToSlack} out of the selected ${
                form.state.memberships.length
              } ${
                form.state.memberships.length ? memberLabel.singular : memberLabel.plural
              } are not connected to Slack. They will not receive your message.`}
              severity={"warning"}
            />
          </div>
        )}
      {isSendingToMemberships ? (
        <CommunityMembersCheckList isCollapsible form={form} memberships={sendTo} />
      ) : (
        <DiscoAlert
          message={`Sending to everyone in ${
            sendTo === "product" ? activeProduct!.name : "your community"
          }.`}
        />
      )}
      <div>
        <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={0.5}>
          {"How to send"}
        </DiscoText>
        <MessageCommunityMembersSendMethodDropdown
          form={form}
          organizationKey={organization}
        />
      </div>

      {form.state.sendMessageMethod === "email" && (
        <MessageCommunityMembersEmailFormFields form={form} />
      )}

      {/* Send As */}
      {form.state.sendMessageMethod === "slack-direct-message" &&
        form.state.slackDirectMessageInput && (
          <AutomationSlackSendAsFields
            slackContent={form.state.slackDirectMessageInput}
          />
        )}
      {/* Only show these fields after a messaging method is picked */}
      {form.state.sendMessageMethod && (
        <>
          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Message"}
              </DiscoText>
            }
            error={Boolean(form.errorsByField.richEditorBody)}
            errorMessages={form.errorsByField.richEditorBody}
            marginBottom={0}
          >
            <DiscoEditor
              key={form.state.sendMessageMethod}
              placeholder={"Write your message here"}
              defaultValue={
                form.state.sendMessageMethod === "email"
                  ? form.state.emailMessageInput?.richEditorBody
                  : form.state.slackDirectMessageInput?.richEditorBody
              }
              onChange={(v) => {
                if (form.state.sendMessageMethod === "email") {
                  form.state.emailMessageInput!.richEditorBody = v
                } else if (form.state.sendMessageMethod === "slack-direct-message") {
                  form.state.slackDirectMessageInput!.richEditorBody = v
                }
              }}
              onMount={setEditor}
              minHeight={200}
              testid={"MessageCommunityMembersForm.rich-editor"}
              config={"email"}
              showOutline
            />
          </DiscoFormControl>
        </>
      )}
    </Form>
  )

  async function handleSendMessage() {
    const { didSave } = await form.submit({
      organizationId: activeOrganization.id,
      organizationMembershipIds: form.state.sendTo
        ? null
        : form.state.memberships.map((m) => m.organizationMembershipId),
      sendTo: form.state.sendTo,
      productId: form.state.sendTo === "product" ? activeProduct?.id : null,
      emailMessageInput: getEmailInput(),
      slackDirectMessageInput: getSlackDirectMessageInput(),
    })
    if (!didSave) return

    const audience = form.state.sendTo
      ? `all ${memberLabel.plural}`
      : `${form.state.memberships.length} ${
          form.state.memberships.length === 1 ? memberLabel.singular : memberLabel.plural
        }`
    displaySuccessToast({
      message: `Sent message to ${audience}`,
      testid: `${testid}.success-toast`,
    })

    onClose()
    onMessage?.()
  }

  function handlePreviewButtonClick() {
    if (form.state.sendMessageMethod === "email") {
      handleSendEmailPreview()
    } else if (form.state.sendMessageMethod === "slack-direct-message") {
      handleSendSlackMessagePreview()
    }
  }

  async function handleSendEmailPreview() {
    const { didSave: didSend } = await previewEmailForm.submit({
      organizationId: activeOrganization.id,
      ...getEmailInput()!,
    })

    if (!didSend) return

    displaySuccessToast({
      message: `Preview email sent to ${authUser.email}`,
      testid: "MessageCommunityMembersForm.preview.success-toast",
    })
  }

  async function handleSendSlackMessagePreview() {
    const { didSave: didSend } = await previewSlackMessageForm.submit({
      organizationId: activeOrganization.id,
      richEditorBody: form.state.slackDirectMessageInput?.richEditorBody || "",
    })

    if (!didSend) return

    displaySuccessToast({
      message: `Slack message preview sent`,
      testid: "MessageCommunityMembersForm.preview.success-toast",
    })
  }

  function getEmailInput() {
    if (form.state.sendMessageMethod !== "email") return
    return {
      subject: form.state.emailMessageInput?.subject || "",
      richEditorBody: form.state.emailMessageInput?.richEditorBody || "",
    }
  }

  function getSlackDirectMessageInput() {
    if (form.state.sendMessageMethod !== "slack-direct-message") return
    const input = form.state.slackDirectMessageInput
    return {
      richEditorBody: input?.richEditorBody || "",
      sendAs: input?.sendAs === "bot" ? null : input?.sendAs,
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    display: "grid",
    gap: theme.spacing(2.5),
    alignContent: "start",
    padding: theme.spacing(2.5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
  sendButton: {
    marginLeft: "auto",
  },
}))

function MessageCommunityMembersDrawerContentSkeleton() {
  const classes = useStyles()

  return (
    <Form
      classes={{ formFieldsContainer: classes.form, buttonsRoot: classes.buttons }}
      height={"100%"}
      hasStickyButtons
      buttons={
        <>
          <DiscoButtonSkeleton width={"180px"} />
          <DiscoButtonSkeleton width={"130px"} className={classes.sendButton} />
        </>
      }
    >
      <CommunityMembersCheckListSkeleton isCollapsible />
      {range(4).map((i) => (
        <DiscoFormControlSkeleton key={i} variant={"one-column"} marginBottom={0} />
      ))}
    </Form>
  )
}

export default Relay.withSkeleton({
  component: observer(MessageCommunityMembersDrawerContent),
  skeleton: MessageCommunityMembersDrawerContentSkeleton,
})
