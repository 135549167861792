import SlackIcon from "@/core/ui/images/logos/slack-icon.svg"
import {
  MessageCommunityMembersFormStore,
  SendMessageMethod,
} from "@/organization/people/actions/message/MessageCommunityMembersDrawerContent"
import { MessageCommunityMembersSendMethodDropdownFragment$key } from "@/organization/people/actions/message/__generated__/MessageCommunityMembersSendMethodDropdownFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoSelect, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  form: MessageCommunityMembersFormStore
  organizationKey: MessageCommunityMembersSendMethodDropdownFragment$key
}

function MessageCommunityMembersSendMethodDropdown({ form, organizationKey }: Props) {
  const organization = useFragment<MessageCommunityMembersSendMethodDropdownFragment$key>(
    graphql`
      fragment MessageCommunityMembersSendMethodDropdownFragment on Organization {
        hasSlackConnection(version: 2)
      }
    `,
    organizationKey
  )

  const theme = useTheme()
  const classes = useStyles()

  const { slackV2 } = useFeatureFlags()

  const sendOptions: { value: SendMessageMethod; title: string }[] = [
    {
      value: "email",
      title: "Email",
    },
    ...ArrayUtils.spreadIf(
      {
        value: "slack-direct-message" as SendMessageMethod,
        title: "Direct Message on Slack",
      },
      slackV2 && organization.hasSlackConnection
    ),
  ]

  return (
    <DiscoSelect
      testid={"MessageCommunityMembersButton.send-method"}
      disableClearable
      value={form.state.sendMessageMethod}
      onChange={(m) => handleSelectSendMessageMethod(m)}
      options={sendOptions}
      placeholder={"Select a sending method"}
      renderOption={(option) => {
        return (
          <div
            className={classes.renderOption}
            data-testid={`MessageCommunityMembersButton.send-method.${option.value}`}
          >
            {getSendMethodIcon(option.value)}
            <DiscoText>{option.title}</DiscoText>
          </div>
        )
      }}
      textFieldInputProps={{
        startAdornment: getSendMethodIcon(form.state.sendMessageMethod) ? (
          <div className={classes.sendMethodIconContainer}>
            {getSendMethodIcon(form.state.sendMessageMethod)}
          </div>
        ) : undefined,
      }}
    />
  )

  function getSendMethodIcon(sendMessageMethod?: SendMessageMethod | null) {
    if (sendMessageMethod === "slack-direct-message") {
      return <SlackIcon className={classes.slackIcon} />
    } else if (sendMessageMethod === "email") {
      return <DiscoIcon icon={"email"} color={theme.palette.groovy.neutral[500]} />
    }
    return null
  }

  function handleSelectSendMessageMethod(sendMessageMethod: SendMessageMethod | null) {
    form.state.sendMessageMethod = sendMessageMethod
  }
}

const useStyles = makeUseStyles((theme) => ({
  renderOption: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  sendMethodIconContainer: {
    display: "flex",
    marginRight: theme.spacing(1),
  },
  slackIcon: {
    width: "24px",
    height: "24px",
  },
}))

export default observer(MessageCommunityMembersSendMethodDropdown)
