/**
 * @generated SignedSource<<b0d50991e0999e45d1484d20866d8cb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MemberGroupFilterInput = {
  memberGroupId?: string | null;
  unassigned?: boolean | null;
};
export type MessageProductMembersButtonQuery$variables = {
  id: string;
  productMemberships?: ReadonlyArray<string> | null;
  memberGroupFilter?: MemberGroupFilterInput | null;
  skip: boolean;
};
export type MessageProductMembersButtonQuery$data = {
  readonly product?: {
    readonly productMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly organizationMembershipId: string;
        };
      }>;
    };
  } | null;
};
export type MessageProductMembersButtonQuery = {
  variables: MessageProductMembersButtonQuery$variables;
  response: MessageProductMembersButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "memberGroupFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productMemberships"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "productMemberships"
        },
        {
          "kind": "Variable",
          "name": "memberGroupFilterInput",
          "variableName": "memberGroupFilter"
        }
      ],
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "productMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "organizationMembershipId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageProductMembersButtonQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "product",
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "MessageProductMembersButtonQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "product",
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "7669c3a39b5caa3b325c8c3d9303c8a2",
    "id": null,
    "metadata": {},
    "name": "MessageProductMembersButtonQuery",
    "operationKind": "query",
    "text": "query MessageProductMembersButtonQuery(\n  $id: ID!\n  $productMemberships: [ID!]\n  $memberGroupFilter: MemberGroupFilterInput\n  $skip: Boolean!\n) {\n  product: node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Product {\n      productMemberships(ids: $productMemberships, memberGroupFilterInput: $memberGroupFilter) {\n        edges {\n          node {\n            id\n            organizationMembershipId\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce0ede975958de8efae37184e59c86a4";

export default node;
