import { handleLocalStorageError } from "@/onboarding/utils/hooks/useLocalStorageState"
import FacebookPixel from "@/organization/tracking/lib/FacebookPixel"
import GoogleAnalytics from "@/organization/tracking/lib/GoogleAnalytics"
import GoogleTagManager from "@/organization/tracking/lib/GoogleTagManager"
import { isE2ETest } from "@utils/e2e"
import { isDevelopmentEnv, isProduction } from "@utils/globalVariables"
import { Location } from "history"
import localforage from "localforage"
import { useEffect, useState } from "react"

/** Track the page view with  */
export const trackPageView = (location: Location) => {
  try {
    GoogleAnalytics.pageView(location.pathname)
    FacebookPixel.pageView()
    GoogleTagManager.pageView(location.pathname)
  } catch (e) {
    // Ignore
  }
}

type CookieHubConfig = {
  cookieHubId?: string
}

export function useInitCookieHub({ cookieHubId }: CookieHubConfig) {
  const [hasConsented, setHasConsented] = useState(false)

  // We have to manually add the script to the head so we can rely on the onload event
  // which doesn't work when using Helmet
  useEffect(() => {
    // comment out the line below to enable cookiehub development script
    if (!cookieHubId || isDevelopmentEnv() || isE2ETest()) return

    try {
      const script = document.createElement("script")
      script.addEventListener("load", init)
      script.src = isProduction()
        ? `https://cookiehub.net/c2/${cookieHubId}.js`
        : `https://dash.cookiehub.com/dev/${cookieHubId}.js`
      const refScript = document.getElementsByTagName("script")[0]
      refScript.parentNode!.insertBefore(script, refScript)
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return hasConsented

  async function init() {
    try {
      // Bypass initializing CookieHub if they've previously consented
      if (await localforage.getItem("disco-cookie-consent")) {
        onAllow("analytics")
        return
      }

      // Initialize CookieHub from the global object loaded by the script
      window.cookiehub.load({
        cookie: {
          // Allow cookie consent to work with subdomains
          domain: "",
        },
        onAllow,
        onRevoke(category: string) {
          if (category === "analytics") {
            localforage.removeItem("disco-cookie-consent")
            setHasConsented(false)

            // Revoke cookie consent permission for Google Aanlytics
            GoogleAnalytics.revokeConsent()
            // Revoke cookie consent permission for Facebook Pixel
            FacebookPixel.revokeConsent()
            // Revoke cookie consent permission for Google Tag Manager
            GoogleTagManager.revokeConsent()
          }
        },
      })
    } catch (err) {
      console.error(`Failed to load Cookiehub: ${err}`)
    }
  }

  function onAllow(category: string) {
    if (category === "analytics") {
      try {
        localforage.setItem("disco-cookie-consent", "1")
      } catch (err) {
        handleLocalStorageError(err)
      }
      setHasConsented(true)

      // Grant cookie consent permission for Google Aanlytics
      GoogleAnalytics.grantConsent()
      // Grant cookie consent permission for Facebook Pixel
      FacebookPixel.grantConsent()
      // Grant cookie consent permission for Google Tag Manager
      GoogleTagManager.grantConsent()
    }
  }
}
