/**
 * @generated SignedSource<<cc87771f0b04b70e5218f9e13b2731ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageCommunityMembersSendMethodDropdownFragment$data = {
  readonly hasSlackConnection: boolean | null;
  readonly " $fragmentType": "MessageCommunityMembersSendMethodDropdownFragment";
};
export type MessageCommunityMembersSendMethodDropdownFragment$key = {
  readonly " $data"?: MessageCommunityMembersSendMethodDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageCommunityMembersSendMethodDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageCommunityMembersSendMethodDropdownFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "version",
          "value": 2
        }
      ],
      "kind": "ScalarField",
      "name": "hasSlackConnection",
      "storageKey": "hasSlackConnection(version:2)"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "17958cb0364634eb0f9d8c1292f36944";

export default node;
